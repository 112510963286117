.t-footer {
  display:flex;
  flex-wrap: wrap;
  background:$c-blue;
  color:$c-white;
  position: relative;
  // &.newsletter-banner-hidden {
  //   margin-top:50px;
  // }
  > .container {
    display:flex;
    flex-wrap:wrap;
    align-items: center;
    flex-basis:auto;
    width:100%;
    justify-content: space-between;
    padding:65px 25px 50px 25px;
    @include mq('tablet-wide', max) {
      padding:50px 25px 25px 25px;
    }
  }

  &__oci-logo {
    display:flex;
    width:100%;
    flex-basis:auto;
    // flex-basis:25%;
    @include mq('tablet-wide', max) {
      // order:3;
      // margin-top:70px;
      width:47.5%;
      flex-basis:auto;
    }
    @include mq('phone-wide', max) {
      width:35%;
      flex-basis:auto;
    }
    svg {
      fill:$c-white;
    }
    svg, img {
      width:120px;
      height:50px;
      @include mq('phablet', max) {
        width:90px;
        height:40px;
      }
      @include mq('phone', max) {
        width:70px;
        height:30px;
      }
    }
  }

  &__bottom-content {
    display:flex;
    width:100%;
    flex-basis:auto;
    margin-top:50px;
    justify-content: space-between;
    align-items: flex-end;
    @include mq('tablet-wide', max) {
      flex-direction: column;
      margin-top:100px;
    }
  }

  &__menu {
    display:flex;
    width:70%;
    flex-basis:auto;
    align-self:flex-start;
    justify-content: flex-end;
    ul {
      display:flex;
      list-style:none;
      padding-left:0;
      margin:0;
      h3 {
        display:none;
      }
      li {
        display: flex;
        margin-right:30px;
        &:last-child {
          margin-right:0;
        }
        a {
          color:$c-white;
          font-weight: $weight-bold;
          text-decoration: none;
          @include font-size(19);
          @include letter-spacing(0.26);
          transition: all $speed-fast ease-in-out;
          &:hover {
            color:$c-light-green;
          }
        }
      }
    }
    @include mq('tablet-wide', max) {
      // order:1;
      width: 47.5%;
      flex-basis:auto;
      justify-content:normal;
      ul {
        flex-direction: column;
        h3 {
          display:flex;
          margin-top:0;
          margin-bottom:3px;
          @include font-size(15);
          @include line-height(24);
          @include letter-spacing(0.09);
        }
        li {
          margin:1px 0;
          a {
            @include font-size(15);
            @include line-height(24);
            @include letter-spacing(0.09);
            font-weight: $weight-regular;
          }
        }
      }
    }
  }

  &__text {
    display:flex;
    width:47.5%;
    flex-basis:auto;
    // flex-basis:27.5%;
    // margin-top:75px;
    @include font-size(13);
    @include line-height(22);
    @include letter-spacing(0.08);
    p {
      margin:0;
    }
    @include mq('desktop', max) {
      width:40%;
    }
    @include mq('tablet-wide', max) {
      // order:2;
      //flex-basis:47.5%;
      width:100%;
      flex-basis:auto;
      // margin-top:0px;
      // align-self:flex-start;
    }
  }

  &__social-media-copyright {
    display:flex;
    width:47.5%;
    flex-basis:auto;
    flex-direction: column;
    @include mq('desktop', max) {
      width:55%;
    }
    @include mq('tablet-wide', max) {
      width:100%;
      flex-basis:auto;
      justify-content: flex-start;
    }
  }

  &__social-media {
    display:flex;
    align-self: flex-end;
    margin-bottom:15px;
    //margin-bottom:-22px;
    &.-mobile {
      display:none;
    }
    &.-mobile {
      @include mq('tablet-wide', max) {
        display:flex;
        width:47.5%;
        flex-basis:auto;
        justify-content: flex-end;
        margin-bottom:0;
      }
      @include mq('phone-wide', max) {
        width:60%;
        flex-basis:auto;
      }
    }
    @include mq('tablet-wide', max) {
      display:none;
      // order:4;
      // flex-basis:47.5%;
      // align-self: normal;
      // // margin-top:70px;
      // justify-content: flex-end;
      // margin-bottom:0;
    }
    a {
      margin-right:20px;
      @include mq('desktop-small', max) {
        margin-right:10px;
      }
      @include mq('tablet-wide', max) {
        margin-right:20px;
      }
      &:last-child {
        margin-right:0;
      }
      &:hover {
        svg, img {
          display:none;
          &.svg--interface--icon-x-hover-dims, &.svg--interface--icon-facebook-hover-dims, &.svg--interface--icon-youtube-hover-dims {
            display:flex;
          }
        }
      }
      svg, img {
        width:40px;
        height:40px;
        &.svg--interface--icon-x-hover-dims, &.svg--interface--icon-facebook-hover-dims, &.svg--interface--icon-youtube-hover-dims {
          display:none;
        }
      }
    }
  }

  &__copyright-text-links {
    display:flex;
    align-self: flex-end;
    justify-content: flex-end;
    width:100%;
    // @include mq('desktop-small', max) {
    //   @include font-size(12);
    // }
    @include mq('tablet-wide', max) {
      // order:5;
      margin-top:30px;
      flex-direction: column;
      // align-self:normal;
      // justify-content: flex-start;
      // margin-top:50px;
      // @include font-size(13);
    }
    @include font-size(13);
    @include line-height(20);
    @include letter-spacing(0.08);
    p {
      margin:0;
    }
    a {
      color:$c-white;
      text-decoration: none;
      &:hover {
        text-decoration: underline;
      }
    }
  }

  &__copyright-text {
    display:flex;
    @include mq('tablet-wide', max) {
      width:100%;
    }
  }

  &__divider {
    display:flex;
    margin:0 5px;
    @include mq('tablet-wide', max) {
      display:none;
    }
  }

  &__copyright-links {
    display:flex;
    @include mq('tablet-wide', max) {
      width:100%;
    }
  }
}
