html {
  box-sizing: border-box;
  height: 100%;
  font-size: 62.5%;
  margin:0 !important;
  padding:0 !important;
}

*, *:before, *:after {
  box-sizing: inherit;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

svg {
  overflow: visible !important; //should fix unwanted clipping
}

iframe {
  border:0;
}

img {
  max-width: 100%;
  height: auto;
}
.scroll-down{
  display:flex;
  align-items:center;
  gap:10px;
  text-decoration:none;
  p{
    color:$c-light-blue;
    line-height:1.3;
  }
  @include mq('origin-break', max) {
    display:none;
  }
  .scroll-down-arrow{
    width: 50px;
    height: 50px;
    border-radius: 100%;
    border:  2px solid $c-light-blue;
    font-size: 30px;
    overflow: hidden; 
    cursor: pointer;
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
    svg{
      animation: scrollDown 1.5s ease infinite;
      path{
        fill:$c-light-blue;
      }
    }
  }
}
.default-gallery {
  ul {
    margin: 0;
    padding: 0;
    list-style-type: none;
    display: grid;
    grid-gap: 15px;
    width:80%;
    @include mq('tablet-wide', max) {
      width:100%;
    }
    @include mq("tablet-small", max){
      grid-template-columns: 1fr !important;
    }
    &.columns-1{
      grid-template-columns: 1fr;
    }
    &.columns-2{
      grid-template-columns: 1fr 1fr;
    }
    &.columns-3{
      grid-template-columns: 1fr 1fr 1fr;
    }
    &.columns-4{
      grid-template-columns: 1fr 1fr 1fr 1fr;
    }
    &.columns-5{
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
      @include mq("tablet", max){
        grid-template-columns: 1fr 1fr 1fr;
      }
    }
    &.columns-6{
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
      @include mq("tablet-wide", max){
        grid-template-columns: 1fr 1fr 1fr 1fr;
      }
      @include mq("tablet", max){
        grid-template-columns: 1fr 1fr 1fr;
      }
    }
    &.columns-7{
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
      @include mq("tablet-wide", max){
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
      }
      @include mq("tablet", max){
        grid-template-columns: 1fr 1fr 1fr 1fr;
      }
    }
    &.columns-8{
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
      @include mq("tablet-wide", max){
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
      }
      @include mq("tablet", max){
        grid-template-columns: 1fr 1fr 1fr 1fr;
      }
    }
    &.columns-9{
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
      @include mq("tablet-wide", max){
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
      }
      @include mq("tablet", max){
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
      }
    }
    li {
      margin: 0;
      padding: 0;
      width:100%;
      img {
        width:100%;
        height:100%;
        object-fit:cover;
      }
    }
  }
}

.clearfix {
  &:after {
    content: "";
    display: table;
    clear: both;
  }
}

.shadow {
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.20);
}

input:focus,
select:focus,
textarea:focus,
button:focus {
  -webkit-appearance: none;
  outline: none;
}

@include placeholder{
  font-style: italic;
  color: lighten($c-text-default, 40%);
  font-family: $font-family-default;
}

.screen-reader-text {
  border: 0;
  clip: rect(1px, 1px, 1px, 1px);
  clip-path: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden  !important;
  padding: 0 !important;
  position: absolute !important;
  width: 1px;
  word-wrap: normal !important;
}

.sow-accordion-panel{
  border-bottom:1px solid $c-ultimate-lightest-gray;
  .sow-accordion-panel-header,
  .sow-accordion-panel-border{
    &:focus{
      outline: none !important;
    }
    .sow-accordion-title{
      @include font-size(17);
      color:$c-blue;
      font-weight:bold;
      margin-left:20px;
    }
    p{
      margin-top:0;
    }
    .sow-accordion-open-button,
    .sow-accordion-close-button{
      span{
        &:before{
          content:url(../svg/interface/icon-arrow-down-dark.svg);
        }
      }
    }
    .sow-accordion-close-button{
      span{
        transition:all $speed-normal ease;
        transform:rotate(270deg);
      }
    }
  }
  .sow-accordion-panel-header{
    padding:15px 0 25px 0 !important;
    background-color:transparent !important;
    flex-direction: row-reverse !important;
  }
  .sow-accordion-panel-border{
    padding:0 0 15px 0 !important;
    background-color:transparent!important;
  }
  .sow-accordion-panel-content{
    color:$c-blue;
  }
}
.textwidget{
  .gform_wrapper{
    background-color:$c-lighter-gray;
    padding:20px;
    .gfield_label{
      font-weight:normal;
    }
    textarea,
    input{
      background-color:$c-lightest-gray;
      border:solid 1px $c-white !important;
    }
  }
}