.o-product-page {
  &__intro{
    padding:5em 0 2em 0;
    text-align:center;
    display:flex;
    flex-direction:column;
    align-items:center;
    h2{
      color:$c-blue;
      @include font-size(18);
      width:60%;
      @include mq("tablet-small", max){
        width:90%;
        padding:0 25px;
      }
    }
  }
  &__background{
    height:60vh;
    min-height:500px;
    max-height: 1000px;
    background-repeat: no-repeat;
    background-size:cover;
    background-position:center;
    @include mq("desktop-wide", min){
      min-height:1000px;
      max-height: 60vh;
    }
  }
  &__content{
    margin-top:-200px;
    .container{
      background-color:$c-white;
      padding-left:40px;
      padding-right:40px;
    }
  }
}