.a-select select, select.a-select {
  @include appearance;
  width: 100%;
  padding: 16px 35px 16px 15px;
  border: 1px solid rgba(155,155,155,0.32);
  background: white;
  box-shadow: 0 0 6px rgba(0,0,0,0.18);
  border-radius: 5px;
  color: #9b9b9b;
  &::-ms-expand {
    display: none;
  }
}

.a-select {
  display: inline-block;
  position: relative;
  svg {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 15px;
  }
  svg + select {
    padding-left:45px;
  }
  &:after {
    content: '';
    display: block;
    width: 10px;
    height: 10px;
    border-bottom: 2px solid black;
    border-right: 2px solid black;
    position: absolute;
    right: 25px;
    top: 50%;
    z-index: 10;
    transform: rotate(45deg) translateY(-100%);
  }
}
