.tablepress-scroll-wrapper {
  .tablepress {
    thead {
      th {
        border-bottom-left-radius: 0px !important;
        border-bottom-right-radius: 0px !important;
        &:first-child {
          border-top-left-radius: 10px;
        }
        &:last-child {
          border-top-right-radius: 10px;
        }
      }
    }
  }
}

.tablepress {
  margin:30px 0 !important;
  width:auto !important;
  .sorting {
    &::after {
      color:$c-white !important;
    }
  }
  thead {
    th {
      color:$c-white !important;
      padding: 15px 25px;
      background:$c-light-blue !important;
      @include font-size(15);
      strong {
        font-size:15px !important;
        line-height:27px !important;
        letter-spacing:0.85px !important;
        color:$c-white !important;
      }
      @include mq('tablepress') {
        &:first-child {
          border-top-left-radius: 10px;
        }
        &:last-child {
          border-top-right-radius: 10px;
        }
      }
      @include mq('tablepress', max) {
        &:first-child {
          border-top-left-radius: 10px;
        }
        &:last-child {
          border-bottom-left-radius: 10px;
        }
      }
    }
  }
  .odd {
    td {
      background-color: rgba(1, 130, 191, 0.03) !important;
    }
  }
  strong {
    font-size:15px !important;
    line-height:27px !important;
    letter-spacing:0.85px !important;
    color:$c-light-blue !important;
  }
  tbody {
    td {
      border-top: 1px solid $c-lightest-blue !important;
      @include mq('tablepress', max) {
        border-bottom:0 !important;
      }
    }
  }
  tfoot {
    th {
      border-top: 1px solid $c-lightest-blue !important;
      @include mq('tablepress', max) {
        border-bottom:0 !important;
      }
    }
  }
  tr {
    td {
      padding:12.5px 10px !important;
      font-size:15px !important;
      line-height:27px !important;
      letter-spacing:0.85px !important;
      color:$c-blue !important;
      @include mq('tablepress') {
        &:first-child {
          padding-left:25px !important;
        }
        &:last-child {
          padding-right:25px !important;
        }
      }
    }
  }
}
.dataTables_wrapper {
  .dataTables_length, .dataTables_filter {
    margin-bottom:10px !important;
  }
  .dataTables_length, .dataTables_filter, .dataTables_info {
    color:$c-light-gray !important;
  }
  .dataTables_length {
    select {
      padding:5px !important;
      font-size:14px !important;
      color:$c-light-blue !important;
      border:1px solid $c-light-blue !important;
    }
  }
  .dataTables_filter {
    input {
      padding:5px !important;
      font-size:14px !important;
      color:$c-light-blue !important;
      border:1px solid $c-light-blue !important;
    }
  }
  .paging_simple {
    .paginate_button {
      color:$c-light-blue !important;
      &::before, &::after {
        color:$c-light-blue !important;
        text-shadow:none !important;
      }
      &.disabled {
        color:$c-light-gray !important;
        &::before, &::after {
          color:$c-light-gray !important;
        }
      }
    }
  }
}
