.o-blog-widget {
  background-color:$c-off-white;
  padding: 50px 0;
  &.articles{
    background-color:transparent;
  }
  &__blogs{
    display:grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap:50px;
    @include mq('tablet', max) {
      display:flex;
      flex-wrap:wrap;
      gap:20px;
    }
  }
  .o-posts-widget__tabs{
    justify-content:flex-start;
  }
  .o-loop-blog{
    width:100%;
    *{
      color:$c-blue;
    }
  }
  .o-content__loop-spinner-overlay {
    background:rgba($c-off-white ,0.75);
  }
}
