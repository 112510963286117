.o-loop-blog {
  display:flex;
  margin-bottom:30px;
  justify-content: space-between;
  text-decoration: none;
  flex-direction: column;
  width:47.5%;
  flex-basis:auto;
  @include mq('tablet', max) {
    width:100%;
    flex-basis:auto;
  }
  &__image-wrapper {
    display: flex;
    position:relative;
    margin-bottom:15px;
    width: 100%;
    height:100%;
    overflow: hidden;
    height:250px;
    @include mq-isolated('tablet', 'tablet-wide') {
      height:225px;
    }
    .category{
      background-color:$c-blue;
      position: absolute;
      left: 20px;
      bottom: 20px;
      color:$c-white;
      padding:5px 10px;
      font-weight:bold;
      z-index:1;
      &.blue{
        background-color:$c-blue;
      }
      &.green{
        background-color:$c-green;
      }
      &.orange{
        background-color:$c-orange;
      }
      &.pink{
        background-color:$c-pink;
      }
      &.gray{
        background-color:$c-gray;
      }
      &.light-blue{
        background-color:$c-light-blue;
      }
      &.yellow{
        background-color:$c-light-yellow;
        color:$c-blue;
      }
    }
  }
  &__image {
    display: flex;
    width:100%;
    height:100%;
    background-size:cover;
    background-repeat:no-repeat;
    background-position: center center;
    transition: all $speed-fast ease-in-out;
  }
  &__content {
    display:flex;
    flex-direction: column;
    flex-grow:1;
  }
  &__type-date {
    @include font-size(13);
    @include line-height(20);
    @include letter-spacing(0.74);
    color:$c-blue;
    svg, img {
      position: relative;
      width:15px;
      top:3px;
      margin-right:5px;
      path {
        fill:$c-light-blue;
      }
    }
  }
  &__type {
    margin-right:7.5px;
    border-right: 1px solid $c-light-blue;
    padding-right:3px;
    transition: all $speed-fast ease-in-out;
  }
  &__date {
    transition: all $speed-fast ease-in-out;
  }
  h2.o-loop-blog__title {
    color:$c-blue;
    @include font-size(19);
    @include line-height(24);
    font-weight:$weight-bold;
    margin-top:7.5px;
    margin-bottom:7.5px;
    transition: all $speed-fast ease-in-out;
  }
  &__excerpt {
    color:$c-blue;
    @include font-size(13);
    @include line-height(21);
    @include letter-spacing(0.74);
  }
  &:hover {
    .o-loop-blog__image{
      transform: scale(1.05);
    }
    .o-loop-blog__title {
      color:$c-light-green;
    }
    .o-loop-blog__type {
      border-right-color:$c-light-green;
    }
  }

}
