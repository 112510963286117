.o-content {
  &.newsletter-banner,
  &.blog{
    padding-bottom:100px;
  }
  &__page-photo-contest{
    background: linear-gradient(90deg, #003a79 20%, #0182bf 75%);
    padding:0 75px 75px;
    padding-bottom: 75px;
    margin-top: 200px;
    .o-content__page-photo-contest-polaroids{
      position:absolute;
    }
    .o-content__page-photo-contest-logo{
      text-align:center;
    }
    *:not(span),
    .breadcrumb_last{
      color:$c-white !important;
    }
    .o-content__return{
      svg{
        path{
          fill:$c-white;
        }
      }
    }
  }
  &__navigation {
    display:none;
    margin-top:25px;
    .nav-links {
      display:flex;
      flex-direction: row;
      > span, > div, > a {
        display:flex;
        flex-direction: column;
        margin-right:10px;
        &:last-child {
          margin-right:0px;
        }
      }
    }
  }
  &__post-count {
    //display: flex;
    width:100%;
    flex-basis:auto;
    align-items: center;
    color:$c-blue;
    @include font-size(13);
    @include line-height(21);
    @include letter-spacing(.8);
    @include mq('tablet-wide', max) {
      flex-basis:auto;
      width:100%;
      justify-content:flex-start;
    }
    @include mq('tablet', max) {
      margin-bottom:10px;
    }
  }
  &__block{
    width:100%;
  }
  &__posts-wrapper {
    display: flex;
    flex-direction: column;
    position: relative;
    width:100%;
    @include mq('tablet', max) {
      margin-top:10px;
    }
  }
  &__loop-main-posts {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    flex-direction: row;
    flex-basis:auto;
    width:100%;
  }
  &__loop-sub-posts {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    flex-direction: row;
    flex-basis:auto;
    width:100%;
    padding-top:10px;
  }
  &__load-more-button-wrapper {
    display:flex;
    justify-content:center;
  }
  &__loop-spinner-overlay {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    width:100%;
    height: 100%;
    left:0;
    background:rgba(255,255,255,0.75);
    z-index:99;
  }
  &__loop-spinner {
    display:flex;
    content:url('../images/gif/spinner1.gif');
    width:25px;
    height:25px;
  }
  &__banner {
    display:flex;
    width: 100%;
    background-size: cover;
    background-position:center center;
    margin-bottom: 50px;
  }
  &__return-breadcrumbs {
    display:flex;
    flex-direction: row;
    align-items: center;
    width:100%;
    gap:50px;
    @include font-size(13);
    @include line-height(20);
    @include letter-spacing(.8);
    @include mq('tablet', max) {
      flex-direction: column;
      align-items: flex-start;
      gap:0;
    }
    h2{
      padding: 5px 1em;
      min-width: 160px;
      text-align: center;
      color:$c-white;
      font-size:21px;
      background-color:$c-blue;
      width:fit-content !important;
      margin:0 !important;
      &.orange{
        background-color:$c-orange;
      }
      &.pink{
        background-color:$c-pink;
      }
      &.green{
        background-color:$c-green;
      }
      &.blue{
        background-color:$c-blue;
      }
    }
  }
  &__return {
    display:flex;
    width:20%;
    flex-basis:auto;
    align-items: center;
    @include mq('tablet', max) {
      width:100%;
      flex-basis:auto;
    }
    a {
      &:not(.button) {
        text-decoration: none;
        &:hover {
          text-decoration:underline;
        }
      }
    }
    svg, img {
      display:flex;
      width:20px;
      margin-right:15px;
    }
    // &:before {
    //   font-family:'Font Awesome 5 Free';
    //   content: '\f060';
    //   font-style: normal;
    //   font-weight:$weight-heavy;
    //   color:$c-light-blue;
    //   margin-right:10px;
    //   display:flex;
    // }
  }
  &__breadcrumbs {
    display:flex;
    width:75%;
    flex-basis:auto;
    @include mq('tablet', max) {
      flex-basis:auto;
      width:100%;
    }
    a {
      color:$c-light-gray;
      text-decoration: none;
    }
  }
  &__archive-breadcrumbs, &__search-breadcrumbs {
    display:flex;
    width:100%;
    flex-basis:auto;
    align-items: center;
    @include font-size(13);
    @include line-height(20);
    @include letter-spacing(.8);
    @include mq('tablet-wide', max) {
      width:100%;
      flex-basis:auto;
    }
    span{
      *{
        &:not(.breadcrumb_last){
          display:none;
        }
      }
    }
  }
  &__wrapper {
    position: relative;
    display:flex;
    flex-direction: column;
    width:100%;
    overflow:hidden;
  }
  &__background {
    position: absolute;
    top:0;
    left:0;
    width:100%;
    height:200px;
    z-index:-2;
    background-position: center center;
    background-repeat:no-repeat;
    background-size:cover;
    &:after{
      content:"";
      width:100%;
      height:100%;
      position:absolute;
      top:100%;
      left:0;
      background: linear-gradient(0deg, rgba(255,255,255,1) 36%, rgba(238,238,238,0.8953956582633054) 100%);
    }
    &.photo-contest{
      background-position:top;
      background-size: contain;
      height:800px;
      &:after{
        content:"";
        background: linear-gradient(to bottom, rgba(255,255,255,0) 20%, #fff 40%, #fff 100%);
        top: 0;
        left: 0;
        position: absolute;
        width: 100%;
        height: 100%;
      }
    }
  }
  &__background-overlay {
    top:0;
    left:0;
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgba(255,255,255,1);
    background: -moz-linear-gradient(top, rgba(255,255,255,1) 100%, rgba(255,255,255,1) 40%, rgba(255,255,255,0) 0%);
    background: -webkit-gradient(left top, left bottom, color-stop(100%, rgba(255,255,255,1)), color-stop(40%, rgba(255,255,255,1)), color-stop(0%, rgba(255,255,255,0)));
    background: -webkit-linear-gradient(top, rgba(255,255,255,1) 100%, rgba(255,255,255,1) 40%, rgba(255,255,255,0) 0%);
    background: -o-linear-gradient(top, rgba(255,255,255,1) 100%, rgba(255,255,255,1) 40%, rgba(255,255,255,0) 0%);
    background: -ms-linear-gradient(top, rgba(255,255,255,1) 100%, rgba(255,255,255,1) 40%, rgba(255,255,255,0) 0%);
    background: linear-gradient(to bottom, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 40%, rgba(255,255,255,1) 100%);
  }
  &__search-form-wrapper {
    display:flex;
    flex-basis:auto;
    width:100%;
    justify-content: flex-end;
    padding:50px 40px 50px 0;
    &.container {
      @include mq('tablet') {
        max-width:1390px;
      }
    }
    @include mq('desktop-wide', max) {
      padding:50px 60px 50px 20px;
    }
  }
  &__search-form {
    display:flex;
    flex-wrap: wrap;
    @include mq('tablet', max) {
      width:100%;
      top:10px;
    }
    input[type="search"] {
      margin:0;
      @include appearance;
      background:#fff;
      padding:7.5px 15px;
      border-top: 1px solid $c-white;
      border-bottom: 1px solid $c-white;
      border-left: 1px solid $c-white;
      border-right:0;
      @include font-size(16);
      display: flex;
      height:45px;
      width:300px;
      color:$c-blue;
      @include mq('tablet', max) {
        padding:8px 18px;
        height:43px;
        @include font-size(15);
      }
      @include mq('phablet', max) {
        padding:6px 14px;
        height:41px;
        @include font-size(14);
      }
      @include mq('phone-wide', max) {
        width:70%;
        padding:4px 10px;
        height:39px;
      }
      &::placeholder {
        color:$c-blue;
        font-style:normal;
      }
    }
  }
  &__search-button {
    display: flex;
    width:45px;
    height:45px;
    background:$c-blue;
    border:0;
    align-items: center;
    cursor:pointer;
    svg {
      path {
        fill:$c-white;
      }
    }
    svg, img {
      left:12px;
      width:40%;
      height:40%;
      position: relative;
      @include font-size(22);
      @include mq('tablet', max) {
        @include font-size(20);
      }
      @include mq('phablet', max) {
        @include font-size(18);
        left:10px;
      }
      @include mq('phone-wide', max) {
        @include font-size(16);
      }
    }
    @include mq('tablet', max) {
      width:43px;
      height:43px;
    }
    @include mq('phablet', max) {
      width:41px;
      height:41px;
    }
    @include mq('phone-wide', max) {
      width:39px;
      height:39px;
    }
  }
  &__title-tab {
    display:flex;
    width:100%;
    @include mq('desktop-wide', max) {
      padding:0 20px;
    }
    @include mq('tablet') {
      position: relative;
    }
    &.-page {
      margin-top:145px;
    }
    h1, h2 {
      display: flex;
      @include font-size(35);
      @include letter-spacing(.8);
      @include line-height(40);
      background:$c-white;
      margin:0;
      padding:20px 30px 0 30px;
      border-top-left-radius:13px;
      border-top-right-radius:13px;
      color:$c-blue;
      @include mq('tablet') {
        position: absolute;
        bottom:-1px;
      }
      @include mq('desktop', max) {
        @include font-size(30);
        @include line-height(35);
      }
      @include mq('tablet-wide', max) {
        @include font-size(25);
        @include line-height(30);
      }
      @include mq('phone-wide', max) {
        @include font-size(20);
        @include line-height(25);
      }
      &.green {
        color:$c-green;
      }
      &.orange {
        color:$c-orange;
      }
      &.pink {
        color:$c-pink;
      }
      &.blue {
        color:$c-blue;
      }
      &.brown {
        color:$c-brown;
      }
      &.light-blue {
        color:$c-light-blue;
      }
    }
  }
  &__single, &__page, &__page-full-width, &__page-navigation-left {
    h1, h2, h3, h4, h5, h6 {
      width:100%;
      @include letter-spacing(.8);
    }
    h1 {
      @include font-size(44);
      @include line-height(55);
      margin-bottom: 20px;
      @include mq('desktop', max) {
        @include font-size(38);
        @include line-height(49);
      }
      @include mq('tablet-wide', max) {
        @include font-size(32);
        @include line-height(43);
      }
      @include mq('phablet', max) {
        @include font-size(26);
        @include line-height(37);
      }
    }
    h2 {
      @include font-size(40);
      @include line-height(50);
      @include mq('desktop', max) {
        @include font-size(32);
        @include line-height(42);
      }
      @include mq('tablet-wide', max) {
        @include font-size(28);
        @include line-height(38);
      }
      @include mq('phablet', max) {
        @include font-size(24);
        @include line-height(34);
      }
    }
    h3 {
      @include font-size(30);
      @include line-height(40);
      @include mq('desktop', max) {
        @include font-size(26);
        @include line-height(36);
      }
      @include mq('tablet-wide', max) {
        @include font-size(22);
        @include line-height(32);
      }
      @include mq('phablet', max) {
        @include font-size(18);
        @include line-height(28);
      }
    }
    h4 {
      @include font-size(24);
      @include line-height(34);
      @include mq('desktop', max) {
        @include font-size(20);
        @include line-height(30);
      }
      @include mq('tablet-wide', max) {
        @include font-size(18);
        @include line-height(28);
      }
      @include mq('phablet', max) {
        @include font-size(16);
        @include line-height(26);
      }
    }
    h5 {
      @include font-size(20);
      @include line-height(30);
      @include mq('desktop', max) {
        @include font-size(18);
        @include line-height(28);
      }
      @include mq('tablet-wide', max) {
        @include font-size(16);
        @include line-height(26);
      }
      @include mq('phablet', max) {
        @include font-size(14);
        @include line-height(24);
      }
    }
    h6 {
      @include font-size(18);
      @include line-height(28);
      @include mq('desktop', max) {
        @include font-size(16);
        @include line-height(26);
      }
      @include mq('tablet-wide', max) {
        @include font-size(14);
        @include line-height(24);
      }
      @include mq('phablet', max) {
        @include font-size(12);
        @include line-height(22);
      }
    }
  }
  &__archive, &__search, &__single, &__page, &__page-full-width, &__page-navigation-left, &__404 {
    display: flex;
    background:$c-white;
    flex-basis:auto;
    width:100%;
    flex-wrap: wrap;
    justify-content: space-between;
    padding:40px;
    align-items: flex-start;
    &-left{
      width:22%;
      @include mq('tablet', max) {
        width:100%;
      }
    }
    &.container {
      @include mq('tablet') {
        max-width:1470px;
      }
    }
    @include mq('desktop-wide', max) {
      width:auto;
      padding:40px 25px 40px 25px;
    }
  }
  &__archive, &__search {
    padding-top:20px;
    h1{
      padding: 5px 1em;
      min-width: 160px;
      text-align: center;
      color:$c-white;
      font-size:21px;
      background-color:$c-blue;
      width:fit-content !important;
      margin-top:20px;
      &.orange{
        background-color:$c-orange;
      }
      &.pink{
        background-color:$c-pink;
      }
      &.green{
        background-color:$c-green;
      }
      &.blue{
        background-color:$c-blue;
      }
    }
  }
  &__single-content {
    display:flex;
    flex-direction: column;
    flex-wrap:wrap;
    flex-basis:auto;
    width:100%;
  }
  &__archive-content, &__search-content {
    display:flex;
    flex-direction: row;
    flex-wrap:wrap;
    width:100%;
    justify-content: center;
    align-items: center;
    justify-content: flex-start;
    margin-top: 25px;
  }
  &__archive-content, &__search-content {
    width:75%;
    flex-basis:auto;
    @include mq('tablet-wide', max) {
      width:70%;
      flex-basis:auto;
    }
    @include mq('tablet', max) {
      width:100%;
      flex-basis:auto;
    }
  }
  &__comments {
    display:none;
    ul {
      list-style:none;
      padding-left:0;
    }
    .comment-form-comment {
      label {
        display:block;
      }
    }
    #comment {
      border:1px solid $c-light-gray;
      border-radius:5px;
    }
  }
  &.-homepage {
    h3.widget-title {
      color: $c-black;
      @include font-size(26);
      @include letter-spacing(.8);
      @include line-height(42);
      font-weight: $weight-bold;
    }
  }
  &__page-sidebar {
    display:flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  nav {
    display:flex;
    width:20%;
    flex-basis:auto;
    flex-direction: column;
    @include mq('tablet', max) {
      width:100%;
      flex-basis:auto;
    }
  }
  &__left {
    display:flex;
    width:75%;
    flex-basis:auto;
    flex-direction: column;
    @include mq('tablet', max) {
      width:100%;
      flex-basis:auto;
    }
  }
  &__right {
    display:flex;
    width:75%;
    flex-basis:auto;
    flex-direction: column;
    margin-top:20px;
    margin-left:auto;
    @include mq('tablet', max) {
      width:100%;
      flex-basis:auto;
    }
  }
  &__page-navigation {
    display:flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top:15px;
    margin-bottom:0;
    &.active {
      .o-content__page-navigation-mobile-toggle {
        background:$c-light-blue;
        color:$c-white;
        svg {
          path {
            fill:$c-white;
          }
        }
      }
    }
    h4 {
      padding-bottom: 15px;
      margin-top: 0;
      margin-bottom: 20px;
      border-bottom: 1px solid #ccc;
      @include font-size(15);
      @include letter-spacing(.8);
    }
    @include mq('tablet', max) {
      &.active {
        margin-bottom:30px;
      }
    }
  }
  &__page-navigation-mobile-toggle {
    display:flex;
    flex-wrap:wrap;
    color:$c-light-blue;
    cursor:pointer;
    border:1px solid $c-light-blue;
    border-radius:99px;
    padding:7.5px 20px;
    font-weight:700;
    align-items: center;
    @include mq('tablet') {
      display:none;
    }
    svg {
      path {
        fill:$c-light-blue;
      }
    }
    svg, img {
      width:20px;
      height:20px;
      margin-right:10px;
    }
    // &:before {
    //   font-family:'Font Awesome 5 Free';
    //   content: '\f07c';
    //   font-style: normal;
    //   font-weight:$weight-heavy;
    //   @include font-size(13);
    //   display:flex;
    //   position: relative;
    //   margin-right:10px;
    //   transition: transform $speed-slow ease-in-out;
    // }
  }
  &__page-navigation-items {
    display:flex;
    flex-direction: column;
    margin-top:20px;
    width:100%;
    @include mq('tablet', max) {
      display:none;
    }
  }
  &__page-navigation-items-group{
    margin-bottom:20px;
    &:not(:last-of-type){
      border-bottom:solid 1px $c-blue;
    }
    .icon{
      cursor:pointer;
      width:15px;
      height:auto;
      svg {
        width: 100%;
        height: auto;
        path{
          fill:$c-blue;
        }
      }
    }
  }
  .sub-pages{
    display:none;
    // &.active{
    //   display:block !important;
    // }
  }
  &__page-navigation-item {
    display: flex;
    // text-decoration: none;
    margin-bottom:20px;
    // align-items: center;
    align-items: flex-start;
    padding-left:20px;
    a{
      text-decoration:none;
      color:$c-blue !important;
      @include font-size(13);
      @include letter-spacing(.8);
      @include line-height(20);
    }
    &.current-page{
      text-decoration:underline;
    }
    &.-main{
      padding-left:0;
      gap:30px;
      a{
        width: calc(100% - 10px);
        font-weight:bold;
        @include font-size(15);
      }
      &.has-sub-pages{
        display:flex;
        justify-content: space-between;
        align-items:center;
      }
    }
    // @include mq('tablet', max) {
    //   align-items: flex-start;
    // }
    &.-parent{
      font-weight:bold;
      padding:20px 0;
      border-bottom: 1px solid $c-lighter-gray;
    }
  }
  &__page-navigation-item-image {
    display:flex;
    width:100%;
    height:100px;
    background-size:cover;
    background-position: center center;
    @include mq('tablet', max) {
      max-width:300px;
      height:150px;
    }
  }
  &__page-navigation-item-label {
    display:flex;
    background:$c-blue;
    width:100%;
    align-items: center;
    justify-content: center;
    text-align: center;
    @include mq('tablet', max) {
      max-width:300px;
    }
  }
  &__page-navigation-item-title {
    display: flex;
    @include font-size(14);
    @include line-height(16);
    margin:0;
    color:$c-white;
    font-weight:$weight-bold;
    padding:10px 20px;
  }
  #breadcrumbs {
    span, a {
      color:$c-light-gray;
      text-decoration: none;
      @include font-size(13);
    }
    a:hover {
      text-decoration: underline;
    }
  }
}