.o-posts-widget {
  > .container {
    padding:0 25px 25px 25px;
    @include mq('tablet', max) {
      padding:0 25px 50px 25px;
    }
  }
  .alter-view {
    justify-content: flex-start;
    .o-loop-main-post {
      margin-right:2%;
    }
    .o-posts-widget__sub-posts {
      @include mq('tablet-wide', max) {
        width:49%;
        flex-basis:auto;
      }
      @include mq('tablet-small', max) {
        width:100%;
        flex-basis:auto;
      }
    }
  }
  &__no-posts-found {
    margin-bottom:50px;
  }
  &__tabs-stamp {
    position: relative;
    @include mq('tablet', max) {
      > .container {
        max-width:unset;
        padding:0 0 0 25px;
      }
    }
  }
  &__tabs {
    display:flex;
    align-items: center;
    justify-content:space-between;
    margin-bottom:35px;
    border-bottom:1px solid #ccc;
    max-width:100%;
    @include mq('tablet-wide', max) {
      max-width:100%;
    }
    @include mq('tablet', max) {
      display:flex;
      justify-content: space-between;
      width: calc(100vw - 25px);
      flex-direction: row;
      overflow: auto;
      -ms-overflow-style: none;
      scrollbar-width: none;
      border:0;
      padding:0;
      &::-webkit-scrollbar {
        display:none;
      }
    }
    &.has-button{
      flex-wrap:wrap;
      h2{
        display:block;
        @include mq('tablet', max) {
          font-size:35px;
        }
      }
      .button{
        margin-right:45px;
      }
    }
  }
  &__tab-title{
    font-size:44px;
    margin-right: 40px;
    color:$c-blue;
    line-height:1;
    width:max-content;
    @include mq('tablet', max) {
      display:none;
    }
    &.mobile{
      display:none;
      font-size:28px;
      @include mq('tablet', max) {
        display:block;
      }
    }
  }
  &__tab-buttons {
    display: flex;
    align-items: center;
    @include mq('tablet', max) {
      min-width:575px;
    }
  }
  &__tab-button {
    display:flex;
    color:$c-blue;
    text-decoration: none;
    @include font-size(15);
    @include line-height(21);
    text-align: center;
    transition: all $speed-fast ease-in-out;
    margin-right:30px;
    cursor:pointer;
    &:last-child {
      margin-right:0;
    }
    &:hover {
      text-decoration: underline;
    }
    &.active {
      padding:7px 20px;
      font-weight:$weight-bold;
      background:$c-blue;
      color:$c-white;
      &:hover {
        text-decoration: none;
      }
    }
  }
  &__posts {
    display: flex;
    align-items: flex-start;
    gap:50px;
    @include mq('tablet', max) {
      gap:20px;
    }
    @include mq('tablet-wide', max) {
      flex-wrap:wrap;
    }
    .o-loop-main-post {
      width:32%;
      flex-basis:auto;
      @include mq('tablet-wide', max) {
        width:100%;
        flex-basis:auto;
      }
    }
    .o-posts-widget__sub-posts {
      display:flex;
      flex-direction: column;
      width:64%;
      flex-basis:auto;
      @include mq('tablet-wide', max) {
        width:100%;
        flex-basis:auto;
        margin-bottom:20px;
        order:1;
        &:last-child {
          margin-bottom:0px;
        }
      }
      @include mq('tablet-small', max) {
        width:100%;
        flex-basis:auto;
      }
    }
    .o-loop-sub-post__read-more {
      display:none;
    }
  }
  .top{
    display:flex;
    justify-content:space-between;
    align-items:flex-end;
    padding-right:25px;
  }
  &__stamp {
    @include mq('desktop', max) {
      display:none;
    }
    img{
      transform: rotate(15deg);
      z-index: -1;
      position: relative;
    }
  }
  .o-mega-menu__sustainable{
    padding:10px;
    margin:0;
    @include mq('tablet-wide', max) {
      display:none;
    }
    &.mobile{
      display:none;
      margin-left:auto;
      width: fit-content;
      height:100%;
      @include mq('tablet-wide', max) {
        display:block;
      }
      @include mq('tablet-small', max) {
        width: 100%;
        display:flex;
        justify-content:center;
      }
    }
  }
}

