body {
  @include line-height();
  @include letter-spacing();
  @include font-size();
  height: 100%;
  font-family: $font-family-default;
  font-weight: $weight-regular;
  color: $c-text-default;
  margin:0 !important;
}

main {
  display:flex;
  padding-top:140px;
  @include mq('desktop', max) {
    padding-top:90px;
  }
}

.container {
  padding: 0 25px;
  max-width: 1440px;
  margin: 0 auto;
  position: relative;
}

body.development {
  &::before {
    content: "mobile 0px";
    position: fixed;
    color: black;
    background: white;
    border: 1px solid grey;
    display: block;
    bottom: 5px;
    left: 5px;
    z-index: 9999;
    padding: 0 5px;
    @each $breakpoint in $breakpoints{
      $key: nth($breakpoint, 1);
      $value: nth($breakpoint, 2);
      @include mq($key, min){
        content: "#{$breakpoint}";
      }
    }
  }
  &::after {
    content: "h mobile 0px";
    position: fixed;
    color: black;
    background: rgb(206, 206, 206);
    border: 1px solid grey;
    display: block;
    bottom: 5px;
    right: 5px;
    z-index: 9999;
    padding: 0 5px;
    @each $breakpoint in $breakpoints{
      $key: nth($breakpoint, 1);
      $value: nth($breakpoint, 2);
      @include mq-height($key, min){
        content: "h #{$breakpoint}";
      }
    }
  }
}

#wpadminbar {
  position: fixed;
}

.admin-bar {
  main {
    padding-top:172px;
  }
  .t-header {
    padding-top:32px;
  }
  @include mq('tablet', max) {
    main {
      padding-top:156px;
    }
    .t-header {
      padding-top:46px;
    }
    .o-nav {
      top:156px;
    }
  }
  @include mq-isolated('tablet', 'desktop') {
    main {
      padding-top:142px;
    }
    .t-header {
      padding-top:32px;
    }
    .o-nav {
      top:142px;
    }
  }
}
