.o-single-post {
  width:100%;
  margin-bottom:30px;

  p, li {
    @include font-size(18);
    width:80%;
    @include mq('tablet-wide', max) {
      width:100%;
      @include font-size(16);
    }
    @include mq('tablet-small', max) {
      @include font-size(15);
    }
    &:first-of-type{
      @include font-size(19);
      @include line-height(29);
      @include mq('tablet-wide', max) {
        width:100%;
        @include font-size(17);
      }
      @include mq('tablet-small', max) {
        @include font-size(16);
      }
    }
  }
  &__meta {
    display:flex;
    flex-direction: row;
    align-items: center;
    margin-bottom:50px;
    @include mq('tablet', max) {
      justify-content: space-between;
    }
    @include mq('phone-wide', max) {
      flex-direction: column;
      align-items: flex-start;
    }
    a.o-single-post__category {
      display:flex;
      color:$c-white;
      padding:5px 30px;
      border-radius:99px;
      margin-right:30px;
      font-weight:$weight-bold;
      text-decoration: none;
      @include font-size(24);
      @include letter-spacing(.8);
      &.-green {
        background:$c-green;
      }
      &.-orange {
        background:$c-orange;
      }
      &.-pink {
        background:$c-pink;
      }
      &.-blue {
        background:$c-blue;
      }
      &.-gray {
        background:$c-dark-gray;
      }
      @include mq('phone-wide', max) {
        margin-bottom:20px;
      }
    }
    .o-single-post__type-date-reading-time {
      display:flex;
      flex-direction: row;
      align-items: center;
      @include mq('phablet', max) {
        align-items: flex-start;
        flex-direction: column;
      }
    }
    .o-single-post__type-date {
      @include font-size(15);
      @include line-height(20);
      @include letter-spacing(.8);
      color:$c-blue;
      margin-right:30px;
      @include mq('phone-wide', max) {
        margin-bottom:5px;
      }
    }
    .o-single-post__type {
      margin-right:7.5px;
      border-right: 1px solid $c-light-blue;
      padding-right:3px;
      transition: all $speed-fast ease-in-out;
      svg, img {
        width:20px;
        height:20px;
        position: relative;
        top:3px;
        margin-right:5px;
      }
    }
    .o-single-post__date {
      transition: all $speed-fast ease-in-out;
    }
    .o-single-post__reading-time {
      @include font-size(15);
      @include line-height(20);
      @include letter-spacing(.8);
      color:$c-blue;
      svg, img {
        width:15px;
        height:15px;
        position: relative;
        top:3px;
        margin-right:5px;
      }
    }
  }
}
