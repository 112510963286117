.a-button,
.button {
  font-family: $font-family-default;
  @include font-size(19);
  @include line-height(28);
  font-weight: $weight-bold;
  text-decoration: none;
  text-align: center;
  display: flex;
  cursor: pointer;
  transition: all $speed-fast ease-in-out;
  min-height: 33px;
  width: fit-content;
  padding: 10px 20px;
  vertical-align: middle;
  background-color: $c-black;
  color: $c-white;
  position: relative;
  border: 0;
  @include mq("desktop", max) {
    @include font-size(22);
  }
  @include mq("desktop-small", max) {
    @include font-size(18);
  }

  &.-photo-contest {
    background: $c-orange;
    text-transform: lowercase;
    @include font-size(14);
    @include line-height(18);
    border-radius: 0;
    display: inline;
    padding: 5px 7.5px;
    min-height: unset;
  }

  &.-photo-contest-big {
    background: $c-orange;
    text-transform: lowercase;
    @include font-size(20);
    border-radius: 0;
    display: inline;
    padding: 10px 15px 10px 25px;
    min-height: unset;
    @include mq("tablet", max) {
      padding: 7.5px 12.5px 7.5px 17.5px;
      @include font-size(18);
    }
    @include mq("phablet", max) {
      padding: 7.5px 10px 7.5px 15px;
      @include font-size(16);
    }
    svg {
      position: absolute;
      top: -35px;
      left: -80px;
      transform: rotate(10deg);
      -webkit-filter: drop-shadow(6px 6px 3px rgba(0, 0, 0, 0.5));
      filter: drop-shadow(6px 6px 3px rgba(0, 0, 0, 0.5));
      width: 100px;
      height: 100px;
      @include mq("tablet", max) {
        width: 75px;
        height: 75px;
        top: -25px;
        left: -60px;
      }
      @include mq("phablet", max) {
        width: 65px;
        height: 65px;
        left: -55px;
      }
    }
  }

  &.-primary {
    background-color: $c-black;
    color: $c-white;
    &:hover {
      background-color: $c-black;
      color: $c-black;
    }
  }

  &.-alternative {
    background-color: $c-gray;
    border: 1px solid $c-gray;
    color: $c-black;
    &:hover {
      background-color: $c-black;
      color: $c-white;
    }
  }

  &.-search {
    border-radius: 0px;
    padding: inherit;
  }

  &.-cta {
    text-transform: lowercase;
    border: 0;
    border-radius: 0px;
    @include letter-spacing(-0.11);
    &.-green {
      background-color: $c-green;
      &:after {
        background-color: $c-blue;
      }
    }
    &.-blue {
      background-color: $c-light-blue;
      &:after {
        background-color: $c-blue;
      }
    }
    &.-orange {
      background-color: $c-orange;
      &:after {
        background-color: $c-blue;
      }
    }
    &:after {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      content: url("../svg/interface/icon-arrow-small-right.svg");
      position: absolute;
      top: 0px;
      padding-top: 3px;
    }
  }

  &.-small {
    @include font-size(16);
    padding: 8px 18px;
    @include mq("tablet-wide", max) {
      padding: 6px 16px;
      @include font-size(15);
    }
    @include mq("tablet", max) {
      padding: 5px 14px;
      @include font-size(14);
    }
    @include mq("phone", max) {
      padding: 4px 12px;
      @include font-size(13);
    }
    &:after {
      width: 42.5px;
      right: -42.5px;
      @include mq("tablet-wide", max) {
        width: 37.5px;
        right: -37.5px;
      }
      @include mq("tablet", max) {
        width: 32.5px;
        right: -32.5px;
      }
      @include mq("phone", max) {
        width: 27.5px;
        right: -27.5px;
      }
    }
  }

  &.-medium {
    @include font-size(19);
    padding: 10px 22px;
    @include mq("tablet-wide", max) {
      padding: 8px 20px;
      @include font-size(18);
    }
    @include mq("tablet", max) {
      padding: 6px 18px;
      @include font-size(17);
    }
    @include mq("phone", max) {
      padding: 4px 16px;
      @include font-size(16);
    }
    &:after {
      width: 45px;
      right: -45px;
      @include mq("tablet-wide", max) {
        width: 40px;
        right: -40px;
      }
      @include mq("tablet", max) {
        width: 35px;
        right: -35px;
      }
      @include mq("phone", max) {
        width: 30px;
        right: -30px;
      }
    }
  }

  &.-large {
    @include font-size(22);
    padding: 14px 26px;
    @include mq("tablet-wide", max) {
      padding: 12px 24px;
      @include font-size(21);
    }
    @include mq("tablet", max) {
      padding: 10px 22px;
      @include font-size(20);
    }
    @include mq("phone", max) {
      padding: 8px 20px;
      @include font-size(19);
    }
    &:after {
      width: 50px;
      right: -50px;
      @include mq("tablet-wide", max) {
        width: 45px;
        right: -45px;
      }
      @include mq("tablet", max) {
        width: 40px;
        right: -40px;
      }
      @include mq("phone", max) {
        width: 35px;
        right: -35px;
      }
    }
  }

  &.-green {
    background-color: $c-green;
  }

  &.-light-green {
    background-color: $c-light-green;
  }

  &.-orange {
    background-color: $c-orange;
  }

  &.-pink {
    background-color: $c-pink;
  }

  &.-blue {
    background-color: $c-blue;
  }

  &.-yellow {
    background-color: $c-yellow;
    color: $c-black;
  }

  &.-transparent {
    background: none;
    border: 1px solid black;
  }

  &:after {
    transition: all $speed-fast ease-in-out;
  }
}

.hide-icon {
  .a-button,
  .button {
    &.-photo-contest-big {
      padding: 10px 15px;
      @include mq("tablet", max) {
        padding: 7.5px 12.5px;
        @include font-size(18);
      }
      @include mq("phablet", max) {
        padding: 7.5px 10px;
        @include font-size(16);
      }
    }
  }
}

body:not(.ie) {
  .a-button,
  .button {
    &:hover {
      box-shadow: $box-shadow-inset-default;
      color: $c-white;
      &:after {
        box-shadow: $box-shadow-inset-default;
        color: $c-white;
      }
    }
  }
}
.page-id-452,
.page-id-10280,
.page-id-10362,
.page-id-10311 {
  .a-button.-cta.-green,
  .button.-cta.-orange {
    background-color: #0ea8ce;
  }
  .a-button.-cta.-orange,
  .button.-cta.-green {
    background-color: #273551;
  }
}
.page-id-17180,
.page-id-17182,
.page-id-17181,
.page-id-17183,
.page-id-20691,
.page-id-20689,
.page-id-20688 {
  .a-button.-cta.-green,
  .button.-cta.-green {
    background-color: #fff158;
    color: #273551;
  }
  .a-button.-cta.-orange,
  .button.-cta.-orange {
    background-color: #273551;
  }
}
.page-id-21004 {
  .a-button.-cta.-green,
  .button.-cta.-green {
    background-color: #bf1572;
    color: #fff;
  }
  .a-button.-cta.-orange,
  .button.-cta.-orange {
    background-color: #273551;
  }
}
