.o-view-photo-of-the-month-widget {
  display:flex;
  text-decoration: none;
  width:100%;
  .triangle {
    @include font-size(16);
    &:first-of-type {
      margin-left:10px;
    }
    &:nth-of-type(2) {
      margin-left:-4px;
    }
  }
  &__icon {
    display:flex;
    justify-content: center;
    width:125px;
    @include mq('phablet', max) {
      width:100px;
    }
    svg, img {
      width:100%;
      height:100%;
      transform:rotate(10deg);
      // -webkit-filter: drop-shadow( 6px 6px 3px rgba(0, 0, 0, .5));
      // filter: drop-shadow( 6px 6px 3px rgba(0, 0, 0, .5));
      @include mq('tablet', max) {
        width:100px;
      }
      @include mq('phablet', max) {
        width:auto;
        height:70px;
      }
      @include mq('phone-wide', max) {
        height:50px;
      }
    }
  }
  &__content {
    display:flex;
    flex-direction: column;
    justify-content: center;
    margin-left:15px;
    width:calc(100% - 125px);
    // margin-right: -40px;
    position: relative;
    @include font-size(20);
    @include mq('tablet', max) {
      @include font-size(18);
    }
    @include mq('phablet', max) {
      @include font-size(16);
      width:calc(100% - 100px);
    }
    .o-view-photo-of-the-month-widget__text {
      color:$c-white;
      margin-bottom:10px;
      padding-right:20px;
    }
    .o-view-photo-of-the-month-widget__month {
      color:$c-white;
      font-weight:$weight-bold;
      border-bottom:1px solid $c-white;
      padding-bottom:15px;
    }
  }
}
