.o-photo-contest-banner-widget {
  padding:25px 0;
  @include mq('desktop', max) {
    padding:50px 0;
  }
  .container {
    display:flex;
    position: relative;
    overflow: hidden;
    @include mq('desktop', max) {
      flex-direction: column;
    }
  }
  &__image-wrapper {
    display:flex;
    position: relative;
    width:60%;
    overflow-x: hidden;
    left:-50px;
    z-index: 9;
    box-shadow: 3px 3px 10px rgba(0,0,0,0.2);
    img {
      position: absolute;
      right: -15px;
      top: 60%;
      padding-right: 30px;
      background: #eb6c21;
    }
    @include mq('desktop', max) {
      width:100%;
      transform:unset;
      left:auto;
      height: 300px;
      img {
        transform:unset;
      }
    }
  }
  &__image {
    display:flex;
    width:100%;
    flex-basis:auto;
    background-size:cover;
    position: relative;
    right:-50px;
    @include mq('desktop', max) {
      transform:unset;
      right:auto;
    }
    &.better-positioning {
      background-position: center bottom;
      @include mq('desktop', max) {
        background-position: center center;
      }
      // @include mq('tablet', max) {
      //   background-position: -50px center;
      // }
      @include mq('phablet', max) {
        background-position: -100px center;
      }
      @include mq('phone-wide', max) {
        background-position: -200px center;
      }
    }
  }
  &__content {
    z-index: 1;
    display:flex;
    color:$c-white;
    width:calc(40% + 100px);
    padding:50px 75px 70px 125px;
    flex-basis:auto;
    flex-direction: column;
    margin-left:-100px;
    background: $c-light-blue;
    @include mq('desktop', max) {
      width:100%;
      padding:50px 75px 75px 75px;
      margin-left:auto;
    }
    @include mq('tablet', max) {
      padding:50px 60px 60px 60px;
    }
    @include mq('phablet', max) {
      padding:50px 40px 60px 40px;
    }
    .o-photo-contest-banner-widget__text {
      h1, h2, h3, h4, h5, h6 {
        margin-top:0;
        color:$c-white;
        @include font-size(44);
        @include line-height(50);
        @include mq('desktop', max) {
          @include font-size(32);
          @include line-height(36);
        }
        @include mq('phablet', max) {
          @include font-size(28);
          @include line-height(32);
        }
      }
      ul, ol {
        padding-left:5px;
        li {
          text-indent: -20px;
          margin-left: 20px;
        }
      }
      a {
        color:$c-white;
      }
    }
    .o-photo-contest-banner-widget__button {
      position: relative;
      margin-top:20px;
      width:fit-content;
      @include mq('desktop', max) {
        margin-top:10px;
      }
      &.hide-icon {
        left:5px;
        .triangle {
          &:first-of-type {
            margin-left:0px;
          }
        }
        img {
          display:none;
        }
      }
      img {
        width:80px;
        transform: rotate(10deg);
        margin-bottom:-25px;
        margin-left:-75px;
      }
      .triangle {
        @include font-size(16);
        @include mq('desktop', max) {
          @include font-size(14);
        }
        &:first-of-type {
          margin-left:10px;
          margin-right:5px;
        }
        &:nth-of-type(2) {
          margin-left:-9px;
          margin-right:10px;
        }
      }
    }
  }
}
