.o-hero-widget {
  display:flex;
  justify-content: flex-end;
  align-items: flex-end;
  background-position: 15% center;
  background-size: contain;
  background-repeat: no-repeat;
  overflow: hidden;
  @include mq('tablet', max) {
    background-position: 90% 15px;
    background-size: 350px;
  }
  &.-title-only {
    .o-hero-widget__wrapper {
      height:auto;
    }
    .o-hero-widget__image {
      height:auto;
    }
    .o-hero-widget__title {
      top:auto;
      margin:0;
      padding:150px 0;
      &.-mobile {
        padding:75px 0;
      }
    }
  }
  .container {
    padding:0;
    width:900px;
    @include mq('desktop-wide', max) {
      width:800px;
      margin-left:200px;
    }
    @include mq('desktop', max) {
      width:700px;
    }
    @include mq('desktop-small', max) {
      width:600px;
    }
    @include mq('tablet-wide', max) {
      width:500px;
    }
    @include mq('tablet-halfwide', max) {
      width:400px;
    }
    @include mq('tablet', max) {
      margin-left:0;
      width:100%;
    }
  }
  &__wrapper {
    display:flex;
    width:85%;
    height: 675px;
    transform: skewX(-7.5deg);
    overflow-x: hidden;
    position: relative;
    left:100px;
    @include mq('desktop', max) {
      width:95%;
    }
    @include mq('tablet', max) {
      height: auto;
      width:100%;
      left:0px;
      transform:none;
      flex-wrap: wrap;
    }
  }
  &__image {
    display:flex;
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
    transform: skewX(7.5deg);
    position: relative;
    left:-100px;
    @include mq('tablet', max) {
      left:0px;
      transform:none;
      height:300px;
    }
    .o-hero-widget__title {
      position: relative;
      top:75px;
      color:$c-white;
      font-weight:normal;
      @include font-size(44);
      @include line-height(55);
      @include mq('tablet-wide', max) {
        @include font-size(34);
        @include line-height(45);
      }
      @include mq('tablet', max) {
        @include font-size(21);
        @include line-height(30);
        top:60px;
        display:none;
      }
      &.-mobile {
        display:none;
        @include mq('tablet', max) {
          display:block;
        }
      }
    }
  }
  &__overlay {
    position: absolute;
    display:flex;
    width:100%;
    height:100%;
    background: $c-light-blue;
    background: -moz-linear-gradient(15deg, $c-light-blue 20%, rgba(255,255,255,0) 75%);
    background: -webkit-linear-gradient(15deg, $c-light-blue 20%, rgba(255,255,255,0) 75%);
    background: linear-gradient(15deg, $c-light-blue 20%, rgba(255,255,255,0) 75%);
  }
  &__content {
    position: relative;
    height:100%;
    flex-direction: column;
    display:flex;
    flex-wrap: wrap;
    @include mq('tablet', max) {
      flex-direction: row;
      padding:25px;
    }
  }
  &__search {
    display:flex;
    flex-wrap: wrap;
    position: relative;
    top:100px;
    @include mq('tablet', max) {
      width:100%;
      top:20px;
    }
    input[type="search"] {
      margin:0;
      @include appearance;
      background:rgba(255,255,255,0.7);
      padding:7.5px 15px;
      border:none;
      @include font-size(16);
      display: flex;
      height:45px;
      width:300px;
      color:$c-blue;
      font-style:normal;
      @include mq('tablet', max) {
        padding:8px 18px;
        height:43px;
        @include font-size(15);
      }
      @include mq('phablet', max) {
        padding:6px 14px;
        height:41px;
        @include font-size(14);
      }
      @include mq('phone-wide', max) {
        width:70%;
        padding:4px 10px;
        height:39px;
      }
      &::placeholder {
        color:$c-blue;
        font-style:normal;
      }
    }
  }
  &__search-button {
    display: flex;
    width:45px;
    height:45px;
    background:$c-blue;
    border:0;
    align-items: center;
    cursor:pointer;
    svg {
      path {
        fill:$c-white;
      }
    }
    svg, img {
      left:12px;
      width:40%;
      height:40%;
      position: relative;
      @include font-size(22);
      @include mq('tablet', max) {
        @include font-size(20);
      }
      @include mq('phablet', max) {
        @include font-size(18);
        left:10px;
      }
      @include mq('phone-wide', max) {
        @include font-size(16);
      }
    }
    @include mq('tablet', max) {
      width:43px;
      height:43px;
    }
    @include mq('phablet', max) {
      width:41px;
      height:41px;
    }
    @include mq('phone-wide', max) {
      width:39px;
      height:39px;
    }
  }
  &__divider {
    display: flex;
    width:100%;
    bottom:200px;
    position: absolute;
    align-items: center;
    @include mq('tablet', max) {
      display:none;
    }
  }
  &__divider-title {
    display:flex;
    color:$c-white;
    font-weight:$weight-bold;
    margin-right:30px;
    @include font-size(17);
    @include letter-spacing(-0.11);
  }
  &__divider-line {
    display:flex;
    height:1px;
    background:rgba(255,255,255,0.45);
    flex-grow:1;
  }
  &__buttons {
    display:flex;
    flex-wrap:wrap;
    justify-content: space-between;
    margin-top:40px;
    position: absolute;
    bottom:50px;
    width:100%;
    @include mq('tablet', max) {
      display:none;
    }
  }
  &__button {
    width:23%;
    height:40px;
    align-items: center;
    justify-content: center;
    margin-bottom:20px;
    @include mq('desktop', max) {
      @include font-size(22);
    }
    @include mq('desktop-small', max) {
      @include font-size(18);
    }
    @include mq('tablet-wide', max) {
      @include font-size(14);
    }
    @include mq('tablet', max) {
      @include font-size(25);
      min-width:200px;
      margin-right:15px;
      &:last-child {
        margin-right:0;
      }
    }
  }
  &__mobile {
    display:none;
    @include mq('tablet', max) {
      display:flex;
      flex-wrap:wrap;
      .o-hero-widget__buttons-mobile-wrapper {
        display:flex;
        justify-content: space-between;
        width: calc(100vw - 25px);
        flex-direction: column;
        overflow: auto;
        -ms-overflow-style: none;
        scrollbar-width: none;
        &::-webkit-scrollbar {
          display:none;
        }
      }
      .o-hero-widget__buttons-mobile {
        display: flex;
      }
      .o-hero-widget__title-buttons-mobile {
        color:$c-blue;
        font-weight:$weight-bold;
        margin-top:10px;
        margin-bottom:30px;
        @include font-size(15);
      }
      .o-hero-widget__span-text-buttons-mobile {
        margin-top:25px;
        color:$c-light-blue;
        @include font-size(13);
        display:flex;
        align-items: center;
        svg, img {
          width:20px;
          margin-left:10px;
        }
        // &:after {
        //   font-family:'Font Awesome 5 Free';
        //   content: '\f061';
        //   font-style: normal;
        //   font-weight:$weight-heavy;
        //   @include font-size(13);
        //   display:flex;
        //   position: relative;
        //   left:7.5px;
        // }
      }
    }
  }
}
