.o-bubbles-widget {
  display: flex;
  flex-wrap:wrap;
  justify-content: center;
  &__bubble-wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap:wrap;
  }
  .o-bubbles-widget__bubble {
    display: flex;
    align-items: center;
    flex-direction: column;
  }
  &__bubble-image-wrapper {
    display:flex;
    justify-content: center;
    width:100%;
  }
  &__bubble-image {
    border-radius:99999px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    background-image: url(http://staging.nutrinorm.nl/wp-content/uploads/2020/11/Zwavel-tekort_Suikerbiet.jpg);
    width: 100%;
    height: 100%;
  }
  &__bubble-text {
    display: flex;
    justify-content: center;
    margin-top:10px;
    p {
      margin:0;
      text-align: center;
    }
  }
  &.-left {
    justify-content: flex-start;
  }
  &.-center {
    justify-content: center;
  }
  &.-right {
    justify-content: flex-end;
  }
  &.-columns-1 {
    .o-bubbles-widget__bubble {
      flex-basis:100%;
    }
  }
  &.-columns-2 {
    .o-bubbles-widget__bubble {
      flex-basis:50%;
    }
  }
  &.-columns-3 {
    .o-bubbles-widget__bubble {
      flex-basis:33.33%;
      @include mq('phablet', max) {
        flex-basis:50%;
      }
    }
  }
  &.-columns-4 {
    .o-bubbles-widget__bubble {
      flex-basis:25%;
      @include mq('tablet-wide', max) {
        flex-basis:33.33%;
      }
      @include mq('phablet', max) {
        flex-basis:50%;
      }
    }
  }
  &.-columns-5 {
    .o-bubbles-widget__bubble {
      flex-basis:20%;
      @include mq('tablet-wide', max) {
        flex-basis:33.33%;
      }
      @include mq('phablet', max) {
        flex-basis:50%;
      }
    }
  }
  &.-container-width-50 {
    .o-bubbles-widget__bubble-wrapper {
      width:50%;
      @include mq('tablet-wide', max) {
        width:75%;
      }
      @include mq('phablet', max) {
        width:100%;
      }
    }
  }
  &.-container-width-75 {
    .o-bubbles-widget__bubble-wrapper {
      width:75%;
      @include mq('phablet', max) {
        width:100%;
      }
    }
  }
  &.-container-width-100 {
    .o-bubbles-widget__bubble-wrapper {
      width:100%;
    }
  }
  &.-small {
    .o-bubbles-widget__bubble {
      margin:10px 0;
    }
    .o-bubbles-widget__bubble-image-wrapper {
      width:80%;
    }
    .o-bubbles-widget__bubble-image {
      width:80%;
      padding-top:80%;
    }
    .o-bubbles-widget__bubble-text {
      p {
        width:80%;
      }
    }
  }
  &.-medium {
    .o-bubbles-widget__bubble {
      margin:12.5px 0;
    }
    .o-bubbles-widget__bubble-image-wrapper {
      width:90%;
    }
    .o-bubbles-widget__bubble-image {
      width:90%;
      padding-top:90%;
    }
    .o-bubbles-widget__bubble-text {
      p {
        width:90%;
      }
    }
  }
  &.-large {
    .o-bubbles-widget__bubble {
      margin:15px;
    }
    .o-bubbles-widget__bubble-image-wrapper {
      width:100%;
    }
    .o-bubbles-widget__bubble-image {
      width:100%;
      padding-top:100%;
    }
    .o-bubbles-widget__bubble-text {
      p {
        width:100%;
      }
    }
    &.-columns-1 {
      .o-bubbles-widget__bubble {
        flex-basis:calc(100% - 30px);
      }
    }
    &.-columns-2 {
      .o-bubbles-widget__bubble {
        flex-basis:calc(50% - 30px);
      }
    }
    &.-columns-3 {
      .o-bubbles-widget__bubble {
        flex-basis:calc(33.33% - 30px);
        @include mq('phablet', max) {
          flex-basis:calc(50% - 30px);
        }
      }
    }
    &.-columns-4 {
      .o-bubbles-widget__bubble {
        flex-basis:calc(25% - 30px);
        @include mq('tablet-wide', max) {
          flex-basis:calc(33.33% - 30px);
        }
        @include mq('phablet', max) {
          flex-basis:calc(50% - 30px);
        }
      }
    }
    &.-columns-5 {
      .o-bubbles-widget__bubble {
        flex-basis:calc(20% - 30px);
        @include mq('tablet-wide', max) {
          flex-basis:calc(33.33% - 30px);
        }
        @include mq('phablet', max) {
          flex-basis:calc(50% - 30px);
        }
      }
    }
  }
}
