.o-photo-contest-sign-up-form {
  > div {
    width:100%;
    overflow:hidden;
  }
  display:flex;
  flex-direction: column;
  padding:50px 100px 50px 60px;
  position: relative;
  align-items: flex-end;
  right:-40px;
  border-left:1px solid $c-orange;
  border-top:1px solid $c-orange;
  border-bottom:1px solid $c-orange;
  @include mq('tablet-wide', max) {
    padding:50px 80px 50px 40px;
  }
  @include mq('tablet', max) {
    right:-25px;
  }
  @include mq('phablet', max) {
    padding:50px 30px;
    left:auto;
    right:auto;
    width:100%;
    border-right:1px solid $c-orange;
  }
  .gform_wrapper {
    width:100%;
    margin-bottom:0;
    .gform_fields {
      .gfield {
        &::before {
          display:none;
        }
        margin-top:10px;
        @include mq('tablet-wide', max) {
          &.gf_left_half, &.gf_right_half {
            width:100%;
          }
        }
        .gfield_label {
          color:$c-white;
          @include mq('phablet', max) {
            @include font-size(14);
          }
        }
        .ginput_container_fileupload {
          margin-top:-3px;
        }
        .ginput_container_radio {
          margin-top:5px;
          margin-bottom:-5px;
          .gfield_radio {
            display: flex;
            margin-left:-5px;
            margin-top:0;
            margin-bottom:0;
            li {
              &::before {
                display:none;
              }
              input {
                display: none;
              }
              label {
                display: flex;
                max-width:100%;
                margin-right:15px;
                font-weight:$weight-regular;
                @include mq('phablet', max) {
                  @include font-size(12);
                }
              }
              input + label:before {
                cursor:pointer;
                content:"";
                width:15px;
                height:15px;
                background:$c-blue;
                position: relative;
                border-radius:99px;
                display: flex;
                margin-top:2.5px;
                margin-left:5px;
                margin-right:7.5px;
              }
              input:checked + label {
                position: relative;
                &:before {
                  background-color: $c-white;
                }
              }
            }
          }
        }
        input, textarea {
          width:100%;
          background:$c-blue;
          border:0;
          &::placeholder {
            color:rgba(255, 255, 255, 0.75);
            font-style: normal;
          }
        }
        textarea {
          margin-bottom:-5px;
        }
        > label {
          font-weight:$weight-regular;
          margin-bottom:0px;
          display:flex;
        }
      }
    }
    button {
      @include line-height(18);
    }
  }
}
