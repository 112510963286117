.o-logos-widget {
  display:flex;
  justify-content: space-between;
  flex-wrap:wrap;
  align-items: center;
  &__logo {
    display:flex;
    width:17.5%;
    flex-basis:auto;
    margin-bottom:15px;
    justify-content: center;
    img {
      width:100%;
      transform:scale(0.75);
      -webkit-filter: grayscale(100%);
      -moz-filter: grayscale(100%);
      -ms-filter: grayscale(100%);
      -o-filter: grayscale(100%);
      filter: grayscale(100%);
      filter: gray;
      opacity: .7;
      transition:all $speed-normal ease;
      &:hover{
        filter: grayscale(0);
        opacity:1;
      }
    }
  }
}
