.o-photo-gallery-widget {
  display:flex;
  flex-direction: column;
  h2 {
    margin-top:0;
    margin-bottom:5px;
    &.-heading-size-small {
      @include font-size(30);
      @include line-height(36);
      @include letter-spacing(-0.2);
      @include mq('tablet-wide', max) {
        @include font-size(24);
        @include line-height(30);
        @include letter-spacing(-0.1);
      }
    }
    &.-heading-size-medium {
      @include font-size(38);
      @include line-height(44);
      @include letter-spacing(-0.22);
      @include mq('tablet-wide', max) {
        @include font-size(26);
        @include line-height(32);
        @include letter-spacing(-0.12);
      }
    }
    &.-heading-size-large {
      @include font-size(44);
      @include line-height(50);
      @include letter-spacing(-0.26);
      @include mq('tablet-wide', max) {
        @include font-size(28);
        @include line-height(34);
        @include letter-spacing(-0.16);
      }
    }
    &.-heading-color-blue {
      color:$c-blue;
    }
    &.-heading-color-light-blue {
      color:$c-light-blue;
    }
    &.-heading-color-orange {
      color:$c-orange;
    }
    &.-heading-color-light-green {
      color:$c-light-green;
    }
  }
  &__wrapper {
    display: flex;
    flex-wrap: wrap;
    padding:30px;
    @include mq('tablet', max) {
      padding:30px 0;
    }
  }
  &__image {
    display:flex;
    flex-basis:calc(20% - 25px);
    position: relative;
    margin:12.5px;
    @include mq('tablet-wide') {
      &:nth-child(5n+1) {
        margin-left:0;
      }
      &:nth-child(5n+5) {
        margin-right:0;
      }
    }
    @include mq-isolated('tablet', 'tablet-wide') {
      flex-basis:calc(25% - 25px);
      &:nth-child(4n+1) {
        margin-left:0;
      }
      &:nth-child(4n+4) {
        margin-right:0;
      }
    }
    @include mq-isolated('phablet', 'tablet') {
      flex-basis:calc(33.33% - 25px);
      &:nth-child(3n+1) {
        margin-left:0;
      }
      &:nth-child(3n+3) {
        margin-right:0;
      }
    }
    @include mq('phablet', max) {
      flex-basis:calc(50% - 25px);
      &:nth-child(2n+1) {
        margin-left:0;
      }
      &:nth-child(2n+2) {
        margin-right:0;
      }
    }
    @include mq('phone-wide', max) {
      flex-basis:calc(50% - 20px);
      margin:10px;
    }
    &:before {
      content:'';
      padding-top:100%;
    }
    &:hover {
      .o-photo-gallery-widget__overlay {
        opacity:1;
      }
    }
  }
  &__overlay {
    position: absolute;
    opacity:0;
    width: 100%;
    height: 100%;
    background-color: rgba(1, 130, 191, 0.53);
    transition: all $speed-fast ease-in-out;
    display:flex;
    align-items: center;
    justify-content: center;
    svg {
      width:40px;
    }
    // &:before {
    //   font-family:'Font Awesome 5 Free';
    //   content:'\f067';
    //   font-size:30px;
    //   font-weight:$weight-bold;
    //   color:$c-white;
    //   position: absolute;
    // }
  }
  &__image-background {
    width: 100%;
    height: 100%;
    background-size:cover;
    left:0;
    top:0;
  }
}
