.o-image-widget {
  display:flex;
  width:100%;
  img {
    object-fit: contain; 
    object-position: unset;
  }
  &.-background-image {
      height:250px;
  }
  &.-small {
    img {
      max-width:50%;
    }
    .o-image-widget__image {
      background-size:50%;
    }
  }
  &.-medium {
    img {
      max-width:75%;
    }
    .o-image-widget__image {
      background-size:75%;
    }
  }
  &.-large {
    img {
      max-width:100%;
    }
    .o-image-widget__image {
      background-size:contain;
    }
  }
  &.-left-h {
    justify-content: flex-start;
    .o-image-widget__image {
      background-position-x:left;
    }
  }
  &.-center-h {
    justify-content: center;
    .o-image-widget__image {
      background-position-x:center;
    }
  }
  &.-right-h {
    justify-content: flex-end;
    .o-image-widget__image {
      background-position-x:right;
    }
  }
  &.-top-v {
    align-items: flex-start;
    .o-image-widget__image {
      background-position-y:top;
    }
  }
  &.-center-v {
    align-items: center;
    .o-image-widget__image {
      background-position-y:center;
    }
  }
  &.-bottom-v {
    align-items: flex-end;
    .o-image-widget__image {
      background-position-y:bottom;
    }
  }
  &__image {
    width:100%;
    height:100%;
    background-repeat:no-repeat;
  }
}
