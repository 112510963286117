.o-newsletter-banner {
  background-color:$c-light-blue;
  width: 100%;
  position: relative;
  // @include mq('desktop', max) {
  //   height:120px;
  // }
  // @include mq('tablet-halfwide', max) {
  //   height:180px;
  // }
  // @include mq('tablet', max) {
  //   height:200px;
  // }
  // @include mq('tablet-small', max) {
  //   height:210px;
  // }
  > .container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-end;
    align-items: center;
    height: 100%;
    z-index: 100;
    // @include mq('desktop', max) {
    //   justify-content: normal;
    // }
    // @include mq('tablet-small', max) {
    //   flex-direction: row;
    //   align-items: flex-start;
    //   justify-content: space-between;
    // }
  }
  &__image, &__image-mobile {
    position: relative;
    margin-top:-39px;
    display:flex;
    flex-direction: column;
    width:18%;
    flex-basis:auto;
    @include mq('desktop', max) {
      width:24%;
      flex-basis:auto;
    }
    @include mq('tablet-halfwide', max) {
      margin-top:0;
      align-self:flex-end;
    }
    @include mq('tablet', max) {
      width:34%;
      flex-basis:auto;
    }
    img {
      width:225px;
      @include mq('tablet-small', max) {
        width:35%;
        min-height:1px;
      }
    }
  }
  &__image {
    @include mq('tablet-small', max) {
      display:none;
    }
    // @include mq('tablet', max) {
    //   flex-basis:48%;
    //   align-self:flex-end;
    // }
  }
  &__text-form-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width:65%;
    flex-basis:auto;
    @include mq('desktop', max) {
      width:72%;
      flex-basis:auto;
      flex-wrap:wrap;
      flex-direction: column;
      justify-content: center;
      // margin-left:75px;
    }
    @include mq('tablet', max) {
      width:62%;
      flex-basis:auto;
    }
    // @include mq('desktop-small', max) {
    //   margin-left:50px;
    // }
    // @include mq('tablet-wide', max) {
    //   margin-left:25px;
    // }
    @include mq('tablet-small', max) {
      display:none;
    }
    // @include mq('tablet', max) {
      // padding:40px 0;
      // margin-left:0px;
      // flex-basis:48%;
      // align-self:center;
    // }
    // @include mq('phablet', max) {
    //   padding:30px 0;
    // }
    // @include mq('phone', max) {
    //   padding:20px 0;
    // }
  }
  &__text, &__text-mobile {
    display:flex;
    color:$c-white;
    flex-direction: column;
    justify-content: center;
    font-weight: $weight-bold;
    position: relative;
    z-index:9;
    width:34%;
    flex-basis:auto;
    @include font-size(19);
    @include line-height(24);
    @include letter-spacing(-0.11);
    @include mq('desktop', max) {
      margin-bottom:10px;
      width:100%;
    }
    @include mq('desktop-small', max) {
      margin-right:0;
      @include font-size(17);
    }
    @include mq('tablet-wide', max) {
      @include font-size(15);
    }
    @include mq('tablet', max) {
      @include font-size(15);
      @include line-height(20);
    }
    @include mq('tablet-small', max) {
      @include font-size(18);
      @include line-height(24);
    }
    @include mq('phablet', max) {
      @include font-size(15);
      @include line-height(19);
    }
    @include mq('phone-wide', max) {
      @include font-size(14);
      @include line-height(16);
    }
  }
  &__form, &__form-mobile {
    display:flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    z-index:9;
    form {
      display: flex;
      flex-direction: row;
      // @include mq('tablet', max) {
      //   display:none;
      // }
      input {
        display:flex;
        flex-direction: column;
        justify-content: center;
        border:0;
        margin:0;
        color:$c-blue;
      }
      input[type="email"] {
        background-color:$c-white;
        padding:7.5px 15px;
        height:45px;
        @include font-size(16);
        @include line-height(25);
        @include letter-spacing(-0.1);
        font-style:normal;
        &::placeholder {
          color:$c-blue;
          font-style:normal;
        }
        @include mq('tablet', max) {
          width:50%;
          padding:8px 20px;
          height:43px;
          @include font-size(15);
        }
        @include mq('phone-wide', max) {
          padding:8px 18px;
          height:41px;
          @include font-size(14);
        }
        @include mq('phone', max) {
          padding:6px 16px;
          height:39px;
          @include font-size(13);
        }
      }
      input[type="submit"] {
        background-color: $c-blue;
        color:$c-white;
        font-weight:$weight-bold;
        padding:7.5px 15px;
        @include font-size(18);
        @include line-height(25);
        @include letter-spacing(-0.11);
        height:45px;
        @include mq('tablet', max) {
          @include font-size(17);
          height:43px;
        }
        @include mq('phone-wide', max) {
          padding:8px 18px;
          height:41px;
        }
        @include mq('phone', max) {
          padding:6px 16px;
          height:39px;
        }
        // @include mq('phablet', max) {
        //   @include font-size(15);
        // }
      }
    }
    // .o-newsletter-banner__form-button {
    //   display:flex;
    //   background-color:$c-light-green;
    //   color:$c-white;
    //   border:0;
    //   align-self: flex-start;
    //   padding:6.5px 20px;
    //   margin-top:12.5px;
    //   @include font-size(17);
    //   @include mq('tablet') {
    //     display:none;
    //   }
    // }
  }
  &__form-newsletter-list {
    display:flex;
    flex-direction: column;
    margin-right:30px;
    @include mq('tablet-halfwide', max) {
      margin-bottom:10px;
    }
    label {
      display:flex;
      color:$c-white;
      align-items: center;
      @include font-size(13);
      @include line-height(27);
      @include letter-spacing(-0.08);
      @include mq('phone-wide', max) {
        @include line-height(16);
        margin-bottom:5px;
      }
      input {
        @include appearance;
        margin-right:10px;
        background: $c-white;
        position: relative;
        border:1px solid $c-lighter-gray;
        width:15px;
        height:15px;
        border-radius:1px;
        &:checked {
          box-shadow:none;
          &::after {
            content:'';
            background-color: $c-blue;
            width:7px;
            height:7px;
            margin:3px;
            display:block;
            position: relative;
          }
        }
      }
    }
  }
  &__form-input-fields {
    display:flex;
    flex-direction: row;
    align-items: center;
    @include mq('tablet-small', max) {
      width: 100%;
    }
  }
  // &__image-text-wrapper-mobile {
  //   display:none;
  //   flex-direction: row;
  //   align-items: center;
  //   justify-content: space-between;
  //   @include mq('tablet-small', max) {
  //     display:flex;
  //   }
  // }
  &__image-mobile {
    display:none;
    width:48%;
    flex-basis:auto;
    position: relative;
    margin-top:0;
    @include mq('tablet-small', max) {
      position: absolute;
      bottom:60px;
      display:flex;
      width:auto;
      flex-basis:auto;
    }
    @include mq('phone-small', max) {
      bottom:50px;
    }
  }
  &__text-form {
    @include mq('tablet-small', max) {
      display:flex;
      flex-direction: column;
      width:60%;
      // margin-top:-120px;
    }
  }
  &__text-mobile {
    display:flex;
    width:100%;
    flex-basis:auto;
    @include mq('tablet-small') {
      display:none;
    }
    // @include mq('phablet', max) {
    //   @include font-size(16);
    //   @include line-height(22);
    // }
    // @include mq('phone-wide', max) {
    //   @include font-size(15);
    //   @include line-height(18);
    // }
  }
  &__form-mobile {
    display:none;
    width:100%;
    flex-basis:auto;
    padding:20px 0 30px 0;
    @include mq('tablet-small', max) {
      display:flex;
    }
    @include mq('phone-wide', max) {
      padding-bottom:20px;
    }
    form {
      input[type="email"] {
        width:100%;
      }
    }
  }
}
