.o-loop-sub-post {
  display:flex;
  width:100%;
  flex-basis:auto;
  margin-bottom:30px;
  justify-content: space-between;
  text-decoration: none;
  gap:40px;
  @include mq('tablet-small', max) {
    gap:20px;
  }
  &__image-wrapper {
    display:flex;
    flex-direction: column;
    margin-bottom:0;
    width:20%;
    height:120px;
    overflow:hidden;
    @include mq('phablet', max) {
      width:80px;
      height:80px;
    }
  }
  &__image {
    display: flex;
    width: 100%;
    height: 100%;
    background-size:cover;
    background-repeat:no-repeat;
    background-position: center center;
    transition: all $speed-fast ease-in-out;
  }
  &__content {
    display:flex;
    flex-direction: column;
    //justify-content:space-between;
    width:calc(100% - 135px);
    flex-basis:auto;
    @include mq('phablet', max) {
      width:calc(100% - 95px);
      flex-basis:auto;
    }
  }
  &__type-date {
    @include font-size(13);
    @include line-height(20);
    @include letter-spacing(.8);
    color:$c-blue;
    svg, img {
      position: relative;
      width:15px;
      top:3px;
      margin-right:5px;
      path {
        fill:$c-light-blue;
      }
    }
  }
  &__type {
    margin-right:7.5px;
    border-right: 1px solid $c-light-blue;
    padding-right:3px;
    transition: all $speed-fast ease-in-out;
  }
  &__date {
    transition: all $speed-fast ease-in-out;
  }
  h2.o-loop-sub-post__title {
    color:$c-blue;
    @include font-size(17);
    @include line-height(22);
    @include letter-spacing(.8);
    font-weight:$weight-bold;
    margin-top:7.5px;
    margin-bottom:7.5px;
    transition: all $speed-fast ease-in-out;
  }
  &__excerpt {
    color:$c-blue;
    @include font-size(13);
    @include line-height(21);
    @include letter-spacing(.8);
    @include mq('tablet-small', max) {
      display:none;
    }
  }
  &__read-more {
    text-decoration: underline;
    color:$c-light-blue;
    @include font-size(13);
    @include line-height(20);
    @include letter-spacing(.8);
  }
  // &:last-child {
  //   margin-bottom:0;
  // }
  &:hover {
    .o-loop-sub-post__title {
      color:$c-light-green;
    }
    .o-loop-sub-post__type-date {
      color:$c-light-green;
      svg {
        path {
          fill:$c-light-green;
        }
      }
    }
    .o-loop-sub-post__type {
      border-right-color:$c-light-green;
    }
    .o-loop-sub-post__image {
      transform: scale(1.05);
    }
    .o-loop-sub-post__read-more {
      color:$c-light-green;
    }
  }
}
