.single {
  .o-text-widget {
    color:$c-black;
    @include font-size(15);
    @include line-height(27);
    @include letter-spacing(0.85);
  }
}
.o-text-widget {
  position: relative;
  @include mq('tablet-wide', max) {
    width:100%;
  }
  @include font-size(19);
  @include line-height(30);
  &.-small {
    @include font-size(16);
    @include line-height(26);
  }
  &.-medium {
    @include font-size(19);
    @include line-height(30);
  }
  &.-large {
    @include font-size(22);
    @include line-height(34);
  }
  &.-width-50 {
    width:50%;
  }
  &.-width-75 {
    width:75%;
  }
  &.-width-100 {
    width:100%;
  }
  h2, h3, h4 {
    margin-top:0;
  }
  p{
    font-weight:normal;
  }
  ul, ol {
    padding-left:15px;
  }
  ul {
    list-style:none;
    li {
      &::before {
        content: "\2022";
        color: $c-blue;
        font-weight: bold;
        margin-right:10px;
        @include font-size(22);
        top: 2px;
        position: relative;
      }
    }
  }
  ol {
    list-style: none;
    counter-reset: counter;
    li {
      counter-increment: counter;
      &::before {
        content: counter(counter) ". ";
        color: $c-blue;
      }
    }
  }
  &.-heading-size-small {
    h2 {
      @include font-size(30);
      @include line-height(36);
      @include letter-spacing(-0.2);
      @include mq('tablet-wide', max) {
        @include font-size(24);
        @include line-height(30);
        @include letter-spacing(-0.1);
      }
    }
    h3 {
      @include font-size(26);
      @include line-height(32);
      @include letter-spacing(-0.2);
      @include mq('tablet-wide', max) {
        @include font-size(22);
        @include line-height(28);
        @include letter-spacing(-0.1);
      }
    }
    h4 {
      @include font-size(22);
      @include line-height(28);
      @include letter-spacing(-0.2);
      @include mq('tablet-wide', max) {
        @include font-size(20);
        @include line-height(26);
        @include letter-spacing(-0.1);
      }
    }
  }
  &.-heading-size-medium {
    h2 {
      @include font-size(38);
      @include line-height(44);
      @include letter-spacing(-0.22);
      @include mq('tablet-wide', max) {
        @include font-size(26);
        @include line-height(32);
        @include letter-spacing(-0.12);
      }
    }
    h3 {
      @include font-size(34);
      @include line-height(40);
      @include letter-spacing(-0.22);
      @include mq('tablet-wide', max) {
        @include font-size(24);
        @include line-height(30);
        @include letter-spacing(-0.12);
      }
    }
    h4 {
      @include font-size(30);
      @include line-height(36);
      @include letter-spacing(-0.22);
      @include mq('tablet-wide', max) {
        @include font-size(22);
        @include line-height(28);
        @include letter-spacing(-0.12);
      }
    }
  }
  &.-heading-size-large {
    h2 {
      @include font-size(44);
      @include line-height(50);
      @include letter-spacing(-0.26);
      @include mq('tablet-wide', max) {
        @include font-size(28);
        @include line-height(34);
        @include letter-spacing(-0.16);
      }
    }
    h3 {
      @include font-size(40);
      @include line-height(46);
      @include letter-spacing(-0.26);
      @include mq('tablet-wide', max) {
        @include font-size(26);
        @include line-height(32);
        @include letter-spacing(-0.16);
      }
    }
    h4 {
      @include font-size(36);
      @include line-height(42);
      @include letter-spacing(-0.26);
      @include mq('tablet-wide', max) {
        @include font-size(24);
        @include line-height(30);
        @include letter-spacing(-0.16);
      }
    }
  }
  &.-heading-color-blue {
    h2, h3, h4, h5, h6 {
      color:$c-blue;
    }
  }
  &.-heading-color-light-blue {
    h2, h3, h4, h5, h6 {
      color:$c-light-blue;
    }
  }
  &.-heading-color-orange {
    h2, h3, h4, h5, h6 {
      color:$c-orange;
    }
  }
  &.-heading-color-light-green {
    h2, h3, h4, h5, h6 {
      color:$c-light-green;
    }
  }
  &.-blue {
    color:$c-blue;
  }
  &.-black {
    color:$c-black;
  }
  &.-gray {
    color:$c-light-gray;
  }
  &.-white {
    color:$c-white;
  }
}
