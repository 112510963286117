.gform_wrapper {
  margin-top:0 !important;
  .gform_fields {
    .gfield_label {
      color:$c-blue;
    }
    .ginput_container {
      input, textarea {
        @include appearance;
        border-radius:0;
        border:1px solid $c-light-gray;
      }
    }

  }
}
