.o-mega-menu {
  display:none;
  // display:flex;
  flex-direction: column;
  position: absolute;
  top:48px;
  // left:0px;
  // left: 357px;
  right: 143px;
  z-index:99999999;
  // background-color:transparent;
  background-color:$c-white;
  background-image: radial-gradient(circle 244px at 132.64% 26.64%, rgba(255, 255, 255, 0.6) 0%, rgba(231, 232, 231, 0.6) 95%, rgba(232, 232, 232, 0.6) 100%, rgba(232, 232, 232, 0.6) 100%);
  @include mq('desktop') {
    // padding-right:20px;
    // left:auto;
  }
  @include mq('tablet', max) {
    display:none;
  }
  &__sustainable{
    background-color:$c-green;
    padding:20px;
    width:fit-content;
    margin: 0 30px 0 auto;
    display:flex;
    justify-content:flex-end;
    text-decoration:none;
    @include mq("tablet-wide", max){
      width:100%;
      margin:30px 0;
      justify-content:center;
      padding:10px;
    }
    span{
      color:$c-white;
      font-weight:bold;
      display:flex;
      align-items:center;
      gap:10px;
      svg{
        fill:$c-white;
        width:30px;
        height:30px;
      }
    }
  }
  &__menu {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    width:100%;
    flex-basis:auto;
    padding:25px 30px;
    ul {
      display:flex;
      flex-wrap: wrap;
      list-style:none;
      width:100%;
      flex-basis:auto;
      height:100%;
      @include reset-ul;
      li {
        display:flex;
        width:100%;
        flex-basis:auto;
        vertical-align: middle;
        position: relative;
        white-space: nowrap;
        @include mq('tablet') {
          width:auto;
          flex-basis:auto;
          display: block;
          background: none;
        }
        &.menu-item-has-children {
          flex-direction: column;
          // padding:30px 12px 55px 12px;
          padding: 0px;
          &.fertilization {
            width:175px;
            &:hover {
              > a {
                color:$c-green;
              }
            }
            .sub-menu {
              a {
                &:hover {
                  color:$c-green;
                }
              }
            }
          }
          &.fertilizers {
            width:185px;
            &:hover {
              > a {
                color:$c-pink;
              }
            }
            .sub-menu {
              a {
                &:hover {
                  color:$c-pink;
                }
              }
            }
          }
          &.soil {
            width:145px;
            &:hover {
              > a {
                color:$c-orange;
              }
            }
            .sub-menu {
              a {
                &:hover {
                  color:$c-orange;
                }
              }
            }
          }
          &.sprinkle {
            width:155px;
            &:hover {
              > a {
                color:$c-orange;
              }
            }
            .sub-menu {
              a {
                &:hover {
                  color:$c-orange;
                }
              }
            }
          }
          &.products {
            width:175px;
            &:hover {
              > a {
                color:$c-light-blue;
              }
            }
            .sub-menu {
              a {
                &:hover {
                  color:$c-light-blue;
                }
              }
            }
          }
          > a {
            margin-bottom:15px;
          }
          // &.fertilization {
          //   @include mq('desktop', max) {
          //     flex-basis:100%;
          //     background-size:contain;
          //     padding:30px 58px 0px 14px;
          //     > a {
          //       display:flex;
          //       flex-direction: column;
          //       margin-bottom:0;
          //       padding-top:0;
          //     }
          //     > ul {
          //       display:flex;
          //       flex-direction: row;
          //       padding-bottom:30px;
          //       li {
          //         padding-right:15px;
          //         &:last-child {
          //           padding-right:0;
          //         }
          //       }
          //     }
          //   }
          //   background-size:cover;
          //   background-image:url('../images/png/mega-menu-fertilization-grass-background.png');
          //   padding:30px 58px 50px 14px;
          //   transition: all $speed-slow ease-in-out;
          //   &.aardappel {
          //     background-image:url('../images/png/mega-menu-fertilization-wheat-background.png');
          //   }
          //   &.biet {
          //     background-image:url('../images/png/mega-menu-fertilization-wheat-background.png');
          //   }
          //   &.gerst {
          //     background-image:url('../images/png/mega-menu-fertilization-wheat-background.png');
          //   }
          //   &.gras {
          //     background-image:url('../images/png/mega-menu-fertilization-grass-background.png');
          //   }
          //   &.mais {
          //     background-image:url('../images/png/mega-menu-fertilization-wheat-background.png');
          //   }
          //   &.tarwe {
          //     background-image:url('../images/png/mega-menu-fertilization-wheat-background.png');
          //   }
          //   &.ui {
          //     background-image:url('../images/png/mega-menu-fertilization-wheat-background.png');
          //   }
          //   &.overige {
          //     background-image:url('../images/png/mega-menu-fertilization-wheat-background.png');
          //   }
          //   > a {
          //     &:hover {
          //       color:rgba(255,255,255,0.75);
          //     }
          //   }
          //   a {
          //     font-weight: $weight-bold;
          //     color:$c-white;
          //   }
          // }
          &.products {
            ul {
              padding-top:5px;
              li {
                margin-bottom:15px;
                &:last-child {
                  margin-bottom:0;
                }
                img {
                  height:15px;
                }
              }
            }
          }
        }
        a {
          display: flex;
          padding: 8px 10px 11px;
          text-decoration: none;
          font-weight: $weight-medium;
          @include font-size(19);
          @include letter-spacing(0.68);
          transition: all $speed-fast ease-out;
          color:$c-blue;
          font-weight: $weight-bold;
          &:hover {
            color:$c-light-blue;
          }
        }
        ul {
          &.sub-menu {
            display: flex;
            flex-direction: column;
            padding-left:10px;
            height:auto;
            li {
              a {
                &:hover {
                  text-decoration: underline;
                }
                font-weight: $weight-regular;
                @include font-size(15);
                line-height:1.4;
                @include letter-spacing(0.09);
                padding:0;
                margin-bottom:10px;
              }
            }
          }
        }
      }
    }
  }
  &__divider {
    display:flex;
    width:100%;
    height:5px;
    background-color:$c-blue;
    // background-image:url('../images/png/gradient-divider.png');
    // background-repeat:no-repeat;
    // background-size:contain;
    // background-position: center center;
  }
  &__services {
    display: flex;
    width:100%;
    flex-basis:auto;
    align-items:center;
    //justify-content:space-between;
    background:$c-lightest-gray;
    .menu-item{
      color:$c-blue;
      text-decoration:none;
      padding-right: 40px;
      font-weight:bold;
      &:not(:first-of-type){
        margin-right:20px;
      }
    }
  }
  &__service {
    display: flex;
    padding:20px 40px;
    a {
      display: flex;
      align-items: center;
      text-decoration: none;
      color:$c-blue;
      margin-right:50px;
      @include font-size(15);
      @include line-height(28);
      @include letter-spacing(-0.09);
      &:hover {
        text-decoration: underline;
      }
      img {
        width:48px;
        border-radius:7px;
        margin-right:15px;
      }
    }
  }
}
