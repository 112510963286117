.o-icon-list-widget {
  .container{
    padding:2em 0;
  }
  &__wrapper{
    gap:40px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    @include mq('tablet-wide', max) {
      justify-content: flex-start;
      gap:20px;
    }
  }
  &__icon{
    width:calc(25% - 40px);
    min-width:300px;
    display:flex;
    align-items:center;
    @include mq('tablet-wide', max) {
      min-width:250px;
    }
    @include mq('tablet-small', max) {
      @include font-size(16);
      min-width:200px;
    }
    img{
      margin-right:20px;
      width: 30%;
      height: 80px;
      object-fit:contain;
    }
    p{
      color:$c-blue;
      @include font-size(22);
      @include mq('tablet-wide', max) {
        @include font-size(18);
      }
      @include mq('tablet-small', max) {
        @include font-size(16);
      }
    }
  }
}
