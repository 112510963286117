.m-language-switcher {
  @include mq('desktop', max) {
    display: none;
  }
  @include mq('desktop') {
    display: flex;
  }
  .divider {
    margin:0 7.5px;
    color:$c-light-gray;
  }
  a {
    color:$c-light-gray;
    text-decoration: none;
    &.active {
      color:$c-blue;
    }
    &:hover {
      color:$c-blue;
    }
  }
  &__mobile {
    @include mq('desktop', max) {
      display: flex;
    }
    @include mq('desktop') {
      display: none;
    }
    position: relative;
    width:80px;
    //right:-25px;
    padding:10px 20px;
    z-index:99;
    &.active {
      background-color:$c-white;
      background-image: radial-gradient(circle 111px at 494.63% 61.58%, rgba(255, 255, 255, 0.6) 0%, rgba(231, 232, 231, 0.6) 95%, rgba(232, 232, 232, 0.6) 100%, rgba(232, 232, 232, 0.6) 100%);
      .m-language-switcher__mobile-list {
        display:flex;
      }
      .m-language-switcher__mobile-wrapper {
        &:after {
          transform:rotate(180deg);
        }
      }
    }
    .m-language-switcher__mobile-wrapper {
      color:$c-blue;
      text-decoration: none;
      text-transform: uppercase;
      cursor:pointer;
      &:after {
        content:" ";
        background-image:url(../svg/interface/icon-arrow-small-down.svg);
        width: 14px;
        height: 7.4px;
        background-size: cover;
        display: inline-block;
        background-repeat: no-repeat;
        position: relative;
        top: -2px;
        left: 7.5px;
        background-repeat: no-repeat;
        transition:all 200ms ease-in-out;
      }
    }
    .m-language-switcher__mobile-list {
      display:none;
      position: relative;
      flex-direction: column;
      top:45px;
      left:0;
      width:100%;
      position: absolute;
      background-color:$c-white;
      background-image: radial-gradient(circle 111px at 494.63% 61.58%, rgba(255, 255, 255, 0.6) 0%, rgba(231, 232, 231, 0.6) 95%, rgba(232, 232, 232, 0.6) 100%, rgba(232, 232, 232, 0.6) 100%);
      padding:15px 20px 10px 20px;
    }
    .m-language-switcher__mobile-list-language {
      text-decoration: none;
      color:$c-light-blue;
      padding-bottom:10px;
      &:hover {
        color:$c-blue;
      }
      &:last-child {
        padding-bottom:0;
      }
    }
  }
}
