.o-photo-of-the-month-widget {
  display:flex;
  flex-direction: column;
  padding:20px 40px;
  position: relative;
  margin-top:100px;
  align-items: flex-end;
  // right:-40px;
  @include mq('origin-break', max) {
    margin-left:50px;
  }
  @include mq('phablet', max) {
    margin-left:0;
  }
  @include mq('tablet', max) {
    // right:-25px;
    // margin-bottom:-50px;
  }
  @include mq('phablet', max) {
    // right:0;
    // left:-25px;
    // width:calc(100% + 50px);
  }
  &.-background-color-orange {
    background:$c-orange;
  }
  &.-background-color-blue {
    background:$c-blue;
  }
  &__image {
    margin-top:-100px;
    display:flex;
    width:100%;
    height:400px;
    background-size:cover;
    background-position: center center;
    border:10px solid $c-white;
    box-shadow:2px 2px 10px rgba(0,0,0,0.5);
    position: relative;
    @include mq('tablet-wide', max) {
      height:300px;
    }
    @include mq('tablet-halfwide', max) {
      height:250px;
    }
    @include mq('phone', max) {
      height:200px;
    }
    @include mq('phone-small', max) {
      height:150px;
    }
    .icon, img:not(.image) {
      position: absolute;
      bottom:-90px;
      left:-65px;
      width:135px;
      transform:rotate(10deg);
      // -webkit-filter: drop-shadow( 6px 6px 3px rgba(0, 0, 0, .5));
      // filter: drop-shadow( 6px 6px 3px rgba(0, 0, 0, .5));
      @include mq('tablet-wide', max) {
        width:115px;
        bottom:-80px;
      }
      @include mq('tablet-halfwide', max) {
        width:100px;
        bottom:-65px;
      }
      // @include mq('origin-break', max) {
      //   width:100px;
      //   bottom:-65px;
      // }
      @include mq('phablet', max) {
        width:80px;
        left:-40px;
        bottom:-60px;
      }
    }
  }
  &__content {
    display:flex;
    flex-direction: column;
    width:80%;
    position: relative;
    margin-left:85px;
    width:calc(100% - 85px);
    @include mq('tablet-wide', max) {
      margin-left:70px;
      width:calc(100% - 70px);
    }
    @include mq('tablet-halfwide', max) {
      margin-left:50px;
      width:calc(100% - 50px);
    }
    // @include mq('phablet', max) {
    //   margin-left:75px;
    //   width:calc(100% - 75px);
    // }
    // @include mq('phone-wide', max) {
    //   margin-left:50px;
    //   width:calc(100% - 50px);
    // }
  }
  &__text {
    color:$c-white;
    strong {
      color:$c-white;
    }
    p {
      margin:0 0 0.5em 0;
    }
    h1, h2, h3, h4, h5, h6 {
      color:$c-white;
      font-weight:$weight-regular;
      margin:1em 0 0.25em 0;
      @include font-size(24);
      @include line-height(30);
      @include mq('tablet', max) {
        @include font-size(22);
        @include line-height(28);
      }
      @include mq('phablet', max) {
        @include font-size(20);
        @include line-height(26);
      }
      strong {
        font-weight:$weight-bold;
      }
    }
  }
  &__button {
    border-top:1px solid $c-white;
    padding-top:15px;
    margin-top:10px;
    width: calc(100% + 40px);
  }
}
