.o-related-posts {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  flex-direction: row;
  padding-bottom:50px;
  width:80%;
  @include mq('tablet-wide', max) {
    width:100%;
  }
  .o-loop-sub-post {
    &:last-child {
      margin-bottom:0;
    }
  }
}
