.o-photo-contest-voting-widget {
  display:flex;
  flex-wrap:wrap;
  justify-content: space-between;
  &__photo {
    display: flex;
    flex-direction: column;
    background:$c-white;
    padding:10px;
    width:calc(33.33% - 10px);
    margin-bottom:20px;
    text-decoration: none;
    &:hover {
      .o-photo-contest-voting-widget__photo-title, .o-photo-contest-voting-widget__photo-text {
        color:$c-green;
      }
      .o-photo-contest-voting-widget__photo-button {
        color:$c-green;
        svg {
          path {
            fill:$c-green;
          }
        }
      }
    }
    &:nth-last-child(1), &:nth-last-child(2), &:nth-last-child(3) {
      margin-bottom:0;
    }
    @include mq('tablet-wide', max) {
      width:calc(50% - 10px);
      &:nth-last-child(3) {
        margin-bottom:30px;
      }
      &:nth-last-child(1), &:nth-last-child(2) {
        margin-bottom:0;
      }
    }
    @include mq('phablet', max) {
      width:100%;
      &:nth-last-child(2), &:nth-last-child(3) {
        margin-bottom:30px;
      }
      &:nth-last-child(1) {
        margin-bottom:0;
      }
    }
  }
  &__photo-image {
    width:100%;
    height:250px;
    background-size:cover;
    background-repeat:no-repeat;
    margin-bottom:1em;
  }
  &__photo-content {
    padding:0 15px;
  }
  &__photo-title {
    color:$c-blue;
    font-weight:$weight-bold;
    margin-bottom:0.5em;
    @include font-size(14);
    transition: all $speed-fast ease-in-out;
  }
  &__photo-text {
    color:$c-blue;
    margin-bottom:1em;
    @include font-size(14);
    transition: all $speed-fast ease-in-out;
  }
  &__photo-button {
    color:$c-orange;
    font-weight:$weight-bold;
    text-transform: uppercase;
    display:flex;
    align-items: center;
    transition: all $speed-fast ease-in-out;
    svg, img {
      width:35px;
      height:35px;
      margin-right:7.5px;
      path {
        transition: all $speed-fast ease-in-out;
      }
    }
  }
  &__photo-popup {
    background:$c-blue;
    background: -moz-linear-gradient(90deg, $c-light-blue 20%, $c-blue 75%);
    background: -webkit-gradient(linear, 20% 0%, 75% 0%, from($c-light-blue), to($c-blue));
    background: -webkit-linear-gradient(left, $c-light-blue 20%, $c-blue 75%);
    background: -o-linear-gradient(90deg, $c-light-blue 20%, $c-blue 75%);
    background: -ms-linear-gradient(90deg, $c-light-blue 20%, $c-blue 75%);
    background: linear-gradient(90deg, $c-light-blue 20%, $c-blue 75%);
    padding:30px;
    width:500px;
    @include mq('tablet-wide', max) {
      width:400px;
    }
    @include mq('phablet', max) {
      width:300px;
    }
    .mfp-close {
      color:$c-white;
    }
    .gform_wrapper {
      .gform_fields {
        input {
          background:$c-blue;
          color:$c-white;
          border:0;
        }
        .gfield_label {
          color:$c-white;
        }
      }
    }
  }
}
