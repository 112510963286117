// =============================================
// Editor styles
// =============================================
.editor,
.o-content,
.mce-content-body {
  @include font-size(16);
  @include line-height(25);
  @include letter-spacing(.8);

  font-family: $font-family-default;
  color: $c-text-default;

  .alignleft {
    float:left;
    margin: 1em 2em 1em 0;
  }

  .aligncenter {
    display: block;
    margin-left: auto;
    margin-right: auto;
  }

  .alignright {
    float:right;
    margin: 1em 0 1em 2em;
  }

  .important {
    color:$c-blue;
    @include font-size(19);
    @include line-height(29);
    @include letter-spacing(.8);
    font-weight:$weight-bold;
  }

  .source {
    color:$c-orange;
    @include font-size(13);
    @include line-height(20);
    @include letter-spacing(.8);
  }

  h1,h2,h3,h4,h5,h6 {
    font-weight: $weight-bold;
    color:$c-blue;
  }

  p, li{
    color:$c-text-default-dark;
    @include font-size(16);
    @include line-height(29);
    @include letter-spacing(.8);
  }

  a {
    &:not(.button) {
      color: $c-light-blue;
      &:hover{
        text-decoration: none;
      }
    }
  }

  b, strong {
    color:$c-blue;
  }

  .wp-caption-dd {
    // color: $c-gray;
    // @include font-size(12);
    // @include line-height(24);
    // @include letter-spacing(1.6);
    // @include mq('origin-break'){
    //   @include font-size(16);
    //   @include line-height(30);
    //   @include letter-spacing(1.6);
    // }
    // text-transform: uppercase;
    text-align: center;
    font-style: italic;
    margin-bottom: 40px;
    color:$c-light-gray;
    @include font-size(14);
    p {
      margin:0;
      @include mq('origin-break',max){
        text-align: left !important;
      }
    }
  }

  div.wp-caption {
    p {
      text-align: center;
      font-style: italic;
      margin-bottom: 40px;
      color:$c-light-gray;
      @include font-size(14);
    }
    img {
      margin-bottom: 0;
    }
    &.alignnone,
    &.aligncenter {
      margin-left: auto;
      margin-right: auto;
    }
  }
}

.mce-content-body {
  > *{
    border: 1px dashed rgba(192, 192, 192, 0.5);
    position: relative;
    z-index: 2;
    &:after {
      content: '';
      display: inline-block;
      border: 1px dashed rgba(192, 192, 192, 0.5);
      border-bottom: none;
      position: absolute;
      color: silver;
      @include font-size(10);
      top: 0;
      left: 0;
      @include line-height(15px);
      transform: translateY(-100%);
      padding: 0 5px;
      font-family: sans-serif;
      @include letter-spacing(.8);
      z-index: 0;
    }
  }
  > p {
    &:after {
      content: 'paragraph';
    }
  }
  h2 {
    &:after {
      content: 'h2';
    }
  }
  h3 {
    &:after {
      content: 'h3';
    }
  }
  h4 {
    &:after {
      content: 'h4';
    }
  }
  h5 {
    &:after {
      content: 'h5';
    }
  }
  h6 {
    &:after {
      content: 'h6';
    }
  }
  img {
    &:after {
      content: 'image';
    }
  }
  div.wp-caption {
    &:after {
      content: 'caption';
    }
  }
  div.gallery {
    border: 1px dashed rgba(192, 192, 192, 0.5);
    position: relative;
    z-index: 2;
    &:after {
      content: 'gallery';
      display: inline-block;
      background-color: rgba(white, 0.5);
      border: 1px dashed rgba(192, 192, 192, 0.5);
      border-bottom: none;
      position: absolute;
      color: silver;
      @include font-size(10px);
      top: 0;
      left: 0;
      @include line-height(15px);
      padding: 0 5px;
      font-family: sans-serif;
      @include letter-spacing(.8);
      z-index: 2;
    }
  }
  iframe {
    &:after {
      content: 'iframe';
    }
  }
  blockquote {
    &:after {
      content: 'blockquote';
    }
  }
}
