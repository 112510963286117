.t-header {
  display: flex;
  position: fixed;
  z-index: 100;
  width: 100%;
  top:0;
  left:0;
  right:0;
  background: $c-white;
  transition:top $speed-normal ease-in-out;
  &.up{
    top:-300px !important;
  }
  .sticky-nav & {
    .t-header__search {
      @include mq('desktop',max) {
        height:0;
        padding-top:0;
        padding-bottom:0;
        overflow:hidden;
      }
      input {
        @include mq('desktop',max) {
          height:0;
        }
      }
      .submit {
        @include mq('desktop',max) {
          height:0;
        }
      }
    }
  }
  > .container {
    display:flex;
    align-items: center;
    justify-content: space-between;
    width:100%;
  }
  &__logo {
    display: flex;
    flex-direction: column;
    z-index:999;
    svg, img {
      width:250px;
      height:140px;
      object-fit: contain;
    }
    @include mq('desktop',max) {
      padding: 0px;
      flex-basis:100%;
      align-items: center;
      svg, img {
        width:200px;
        height:90px;
      }
    }
    @include mq('tablet-small', max) {
      svg, img {
        width:185px;
      }
    }
    @include mq('phone-wide', max) {
      svg, img {
        width:170px;
      }
    }
  }
  &__language-nav {
    display:flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: space-between;
    height:110px;
    min-width:fit-content;
    @include mq('desktop', max) {
      justify-content: center;
    }
  }
  &__search-icon {
    display:flex;
    flex-direction: column;
    position: relative;
    transition: all $speed-fast ease-out;
    z-index:9999;
    padding:30px 0;
    @include mq('desktop') {
      padding:30px 0 30px 30px;
    }
    &::after {
      font-family:'Font Awesome 5 Free';
      content: '\f002';
      font-style: normal;
      font-weight:$weight-heavy;
      @include font-size(21);
      display:flex;
      color: $c-blue;
      position: relative;
      cursor:pointer;
      transition: all $speed-fast ease-out;
    }
    &:hover {
      .t-header__search {
        display:flex;
      }
      &::after {
        color:$c-light-blue;
      }
    }
  }
  &__search {
    padding:25px;
    display:none;
    position:absolute;
    left:-240px;
    top:70px;
    background:$c-white;
    box-shadow: 0 0 10px 0 rgba(0,0,0,0.10);
    input[type="search"] {
      margin:0;
      @include appearance;
      background:$c-white;
      padding:10px 15px;
      border-top: 1px solid $c-blue;
      border-bottom: 1px solid $c-blue;
      border-left: 1px solid $c-blue;
      border-right:0;
      @include font-size(14);
      display: flex;
      height:32px;
      width:200px;
      color:$c-blue;
      &::placeholder {
        color:$c-blue;
        font-style:normal;
      }
    }
    button {
      display: flex;
      width:32px;
      height:32px;
      background:$c-blue;
      border:0;
      border-top-right-radius: 99px;
      border-bottom-right-radius: 99px;
      align-items: center;
      cursor:pointer;
      transition: all $speed-fast ease-out;
      &:hover {
        box-shadow:$box-shadow-inset-default;
      }
      &:after {
        font-family:'Font Awesome 5 Free';
        content: '\f002';
        font-style: normal;
        font-weight:$weight-heavy;
        @include font-size(16);
        display:flex;
        color: $c-white;
        position: relative;
        left:0px;
      }
    }
  }
}
