// =============================================
// Colors
// =============================================
$c-yellow: #fee40e;
$c-light-yellow: #FFF158;
$c-orange: #EC681B;
$c-pink: #BF1572;
$c-red: #c12812;
$c-brown: #90695B;
$c-gray: #9b9b9b;
$c-dark-gray: #333333;
$c-light-gray: #99979a;
$c-lighter-gray: #cccccc;
$c-lightest-gray: #d8d8d8;
$c-ultimate-lightest-gray: #e8e8e8;
$c-ultimate-uber-lightest-gray: #f4f3f3;
$c-green: #3E9A66;
$c-dark-green: #365e1c;
$c-light-green: #3E9A66;
$c-purple: #432e50;
$c-blue: #243551;
$c-light-blue: #0EA8CE;
$c-lighter-blue: #1883bd;
$c-lightest-blue: #d9f2fd;
$c-black: #000;
$c-white: #fff;
$c-off-white: #EFEFEF;

$c-transparent:transparent;

$c-status-red:#dc3232;
$c-status-orange:#f56e28;
$c-status-green:#46b450;

$c-text-default: $c-dark-gray;
$c-text-default-light: #8a8a8a;
$c-text-default-dark: #303030;

$color-primary-action: $c-black;
$color-secondary-action: $c-gray; 
