// =============================================
// Variables
// =============================================
$breakpoints: (
  "phone-small":      375px,
  "phone":            400px,
  "phone-wide":       480px,
  "phablet":          560px,
  "tablet-small":     640px,
  "tablet":           768px,
  "origin-break":     780px,
  "tablet-halfwide":  880px,
  "tablepress":       979px,
  "tablet-wide":      1024px,
  "desktop-small":    1180px,
  "desktop":          1250px,
  "desktop-semi-wide":1340px,
  "desktop-wide":     1440px
);

$weight-light:    300;
$weight-regular:  400;
$weight-medium:   500;
$weight-bold:     700;
$weight-heavy:    900;

$font-family-default:    'Inter', sans-serif;
$font-size-default:      16;
$line-height-default:    26;
$letter-spacing-default: 0.26;

$box-shadow-inset-default: inset 0 0 100px rgba(0, 0, 0, 0.5);

$speed-fast:   0.1s;
$speed-normal: 0.2s;
$speed-slow:   0.4s;
