.m-sticky-share-bar {
  display:flex;
  background:$c-light-blue;
  padding:8px 8px 14px 8px;
  position: fixed;
  z-index:99;
  right:0;
  top:300px;
  flex-direction: column;
  align-items: center;
  @include font-size(13);
  @include letter-spacing(0.74);
  @include line-height(20);
  &__text {
    display:flex;
    color:$c-white;
    margin-bottom:10px;
  }
  &__icons {
    display:flex;
    flex-direction: column;
  }
  &__icon {
    width:30px;
    height:30px;
    margin-bottom:10px;
    &:last-child {
      margin-bottom:0;
    }
    &:hover {
      svg, img {
        display:none;
        &.svg--interface--icon-linkedin-hover-dims, &.svg--interface--icon-facebook-hover-dims, &.svg--interface--icon-twitter-hover-dims {
          display:flex;
        }
      }
    }
    svg, img {
      width:100%;
      height:100%;
      &.svg--interface--icon-linkedin-hover-dims, &.svg--interface--icon-facebook-hover-dims, &.svg--interface--icon-twitter-hover-dims {
        display:none;
      }
    }
  }
}
