// =============================================
// Mixins
// =============================================
@mixin font-size($sizeValue: $font-size-default) {
  font-size: $sizeValue + px;
  font-size: ($sizeValue * 0.1) + rem;
}

@mixin line-height($sizeValue: $line-height-default, $important: '') {
  line-height: $sizeValue + px + $important;
  line-height: ($sizeValue * 0.1) + rem + $important;
}

@mixin letter-spacing($sizeValue: $letter-spacing-default) {
  letter-spacing: $sizeValue + px;
  letter-spacing: ($sizeValue * 0.1) + rem;
}

@mixin clearfix {
  &:after {
    content: "";
    display: table;
    clear: both;
  }
}

@mixin v-align {
  top:50%;
  transform:translateY(-50%);
  position: relative;
}

@mixin reset-ul {
  list-style-type: none;
  margin:0;
  padding:0;
  li {
    margin: 0;
    padding: 0;
  }
}

@mixin blur($amount: 5px) {
  -webkit-filter: blur($amount);
  -moz-filter: blur($amount);
  -o-filter: blur($amount);
  -ms-filter: blur($amount);
  filter: blur($amount);
}

@mixin appearance {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  &::-ms-expand {
    display: none;
  }
}

@mixin gradient($from, $to) {
  background-color: $from;
  background-image: linear-gradient(to bottom, $from, $to);
  background-image: -moz-linear-gradient($from, $to);
  background-image: -webkit-gradient(linear, 0% 0%, 0% 100%, from($from), to($to));
  background-image: -webkit-linear-gradient($from, $to);
  background-image: -o-linear-gradient($from, $to);
}

@mixin placeholder(){
  ::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    @content;
  }
  ::-moz-placeholder { /* Firefox 19+ */
    @content;
  }
  :-ms-input-placeholder { /* IE 10+ */
    @content;
  }
  :-moz-placeholder { /* Firefox 18- */
    @content;
  }
}

@mixin mq($width, $type: min) {
  @if map_has_key($breakpoints, $width) {
    $width: map_get($breakpoints, $width);
    @if $type == max {
      $width: $width - 1px;
    }
    @media only screen and (#{$type}-width: $width) {
      @content;
    }
  }
}

@mixin mq-isolated($min-width, $max-width) {
  @if map_has_key($breakpoints, $min-width) {
    $min-width: map_get($breakpoints, $min-width);
    @if map_has_key($breakpoints, $max-width) {
      $max-width: map_get($breakpoints, $max-width);
      $max-width: $max-width - 1px;
      @media only screen and (min-width: $min-width) and (max-width: $max-width){
        @content;
      }
    }
  }
}

@mixin mq-height($height, $type: min) {
  @if map_has_key($breakpoints, $height) {
    $height: map_get($breakpoints, $height);
    @if $type == max {
      $height: $height - 1px;
    }
    @media only screen and (#{$type}-height: $height) {
      @content;
    }
  }
}

@mixin centerline() {
  background: linear-gradient(to bottom, rgba(255,183,107,1) 0%,rgba(255,167,61,1) 50%,rgba(255,124,0,1) 51%,rgba(255,127,4,1) 100%);
}

@keyframes scrollDown{
  0%{
      transform: translateY(-15px);
      opacity: 0;
  }
  50%{
      transform: translateY(0px);
      opacity: 1;
  }
  100%{
      transform: translateY(15px);
      opacity: 0;
  }
}