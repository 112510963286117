.o-cta-banner-widget {
  background:$c-ultimate-lightest-gray;
  width:100%;
  height: auto;
  position: relative;
  .container{
    background-color:transparent !important;
  }
  @include mq('desktop', max) {
    height: auto;
  }
  &.-large-left-side {
    @include mq('tablet') {
      .o-cta-banner-widget__image {
        width:60%;
        flex-basis:auto;
      }
      .o-cta-banner-widget__content {
        width:38%;
        flex-basis:auto;
      }
    }
  }
  &.-equal-sides {
    @include mq('tablet') {
      .o-cta-banner-widget__image {
        width:48%;
        flex-basis:auto;
      }
      .o-cta-banner-widget__content {
        width:48%;
        flex-basis:auto;
      }
    }
  }
  &.-large-right-side {
    @include mq('tablet') {
      .o-cta-banner-widget__image {
        width:38%;
        flex-basis:auto;
      }
      .o-cta-banner-widget__content {
        width:60%;
        flex-basis:auto;
      }
    }
  }
  &.-large-left-side-mobile {
    @include mq('tablet', max) {
      .o-cta-banner-widget__image-mobile {
        width:60%;
        flex-basis:auto;
      }
      .o-cta-banner-widget__content-top {
        width:38%;
        flex-basis:auto;
      }
    }
  }
  &.-equal-sides-mobile {
    @include mq('tablet', max) {
      .o-cta-banner-widget__image-mobile {
        width:48%;
        flex-basis:auto;
      }
      .o-cta-banner-widget__content-top {
        width:48%;
        flex-basis:auto;
      }
    }
  }
  &.-large-right-side-mobile {
    @include mq('tablet', max) {
      .o-cta-banner-widget__image-mobile {
        width:38%;
        flex-basis:auto;
      }
      .o-cta-banner-widget__content-top {
        width:60%;
        flex-basis:auto;
      }
    }
  }
  &.-image-small {
    .o-cta-banner-widget__image, .o-cta-banner-widget__image-mobile {
      img {
        width:150px;
      }
    }
  }
  &.-image-medium {
    .o-cta-banner-widget__image, .o-cta-banner-widget__image-mobile {
      img {
        width:300px;
      }
    }
  }
  &.-image-large {
    .o-cta-banner-widget__image, .o-cta-banner-widget__image-mobile {
      img {
        width:450px;
      }
    }
  }
  &.-image-left-h {
    .o-cta-banner-widget__image, .o-cta-banner-widget__image-mobile {
      justify-content: flex-start;
    }
  }
  &.-image-center-h {
    .o-cta-banner-widget__image, .o-cta-banner-widget__image-mobile {
      justify-content: center;
    }
  }
  &.-image-right-h {
    .o-cta-banner-widget__image, .o-cta-banner-widget__image-mobile {
      justify-content: flex-end;
    }
  }
  &.-image-top-v {
    .o-cta-banner-widget__image, .o-cta-banner-widget__image-mobile {
      align-items: flex-start;
    }
  }
  &.-image-center-v {
    .o-cta-banner-widget__image, .o-cta-banner-widget__image-mobile {
      align-items: center;
    }
  }
  &.-image-bottom-v {
    .o-cta-banner-widget__image, .o-cta-banner-widget__image-mobile {
      align-items: flex-end;
    }
    .o-cta-banner-widget__image, .o-cta-banner-widget__image-mobile {
      img {
        position: absolute;
        bottom:0;
        left: 50%;
        transform: translateX(-50%);
      }
    }
  }
  // &.-radial-gradient-left-h {
  //   .o-cta-banner-widget__radial-gradient-background-wrapper {
  //     justify-content: flex-start;
  //   }
  // }
  // &.-radial-gradient-center-h {
  //   .o-cta-banner-widget__radial-gradient-background-wrapper {
  //     justify-content: center;
  //   }
  // }
  // &.-radial-gradient-right-h {
  //   .o-cta-banner-widget__radial-gradient-background-wrapper {
  //     justify-content: flex-end;
  //   }
  // }
  // &.-radial-gradient-top-v {
  //   .o-cta-banner-widget__radial-gradient-background-wrapper {
  //     align-items: flex-start;
  //   }
  // }
  // &.-radial-gradient-center-v {
  //   .o-cta-banner-widget__radial-gradient-background-wrapper {
  //     align-items: center;
  //   }
  // }
  // &.-radial-gradient-bottom-v {
  //   .o-cta-banner-widget__radial-gradient-background-wrapper {
  //     align-items: flex-end;
  //   }
  // }
  &.-no-apps {
    .o-cta-banner-widget__content-top {
      padding-bottom:0;
      @include mq('tablet', max) {
        padding:50px 0;
      }
    }
    .o-cta-banner-widget__content {
      @include mq('desktop', max) {
        padding:50px 0;
      }
      @include mq('tablet', max) {
        padding:0;
      }
    }
  }
  &.-mirror {
    .o-cta-banner-widget__image, .o-cta-banner-widget__image-mobile {
      order:2;
      justify-content: flex-end;
    }
    .o-cta-banner-widget__content-bottom {
      order:3;
    }
    .o-cta-banner-widget__content {
      order:1;
    }
    .o-cta-banner-widget__wrapper {
      justify-content: space-between;
    }
  }
  > .container {
    height:100%;
  }
  &__wrapper {
    display:flex;
    height:100%;
    flex-direction: row;
    justify-content: space-between;
  }
  // &__radial-gradient-background-wrapper {
  //   position: relative;
  //   width: 100%;
  //   height: 100%;
  //   overflow: hidden;
  //   display:flex;
  //   align-items: center;
  // }
  // &__radial-gradient-background {
  //   position: absolute;
  //   width: calc(100% - 50px);
  //   height: 0;
  //   padding-top: calc(100% - 50px);
  //   &.-green {
  //     background: -moz-radial-gradient(center, ellipse farthest-corner, rgba(135,188,38,1) 15%, rgba(255, 255, 255, 0) 70%);
  //     background: -webkit-gradient(radial, center center, 0px, center center, 100%, color-stop(15%, rgba(135,188,38,1)), color-stop(70%, rgba(255, 255, 255, 0)));
  //     background: -webkit-radial-gradient(center, ellipse farthest-corner, rgba(135,188,38,1) 15%, rgba(255, 255, 255, 0) 70%);
  //     background: -o-radial-gradient(center, ellipse farthest-corner, rgba(135,188,38,1) 15%, rgba(255, 255, 255, 0) 70%);
  //     background: -ms-radial-gradient(center, ellipse farthest-corner, rgba(135,188,38,1) 15%, rgba(255, 255, 255, 0) 70%);
  //     background: radial-gradient(ellipse at center, rgba(135,188,38,1) 15%, rgba(255, 255, 255, 0) 70%);
  //   }
  //   &.-blue {
  //     background: -moz-radial-gradient(center, ellipse farthest-corner, rgba(0,58,121,0.75) 15%, rgba(255, 255, 255, 0) 70%);
  //     background: -webkit-gradient(radial, center center, 0px, center center, 100%, color-stop(15%, rgba(0,58,121,0.75)), color-stop(70%, rgba(255, 255, 255, 0)));
  //     background: -webkit-radial-gradient(center, ellipse farthest-corner, rgba(0,58,121,0.75) 15%, rgba(255, 255, 255, 0) 70%);
  //     background: -o-radial-gradient(center, ellipse farthest-corner, rgba(0,58,121,0.75) 15%, rgba(255, 255, 255, 0) 70%);
  //     background: -ms-radial-gradient(center, ellipse farthest-corner, rgba(0,58,121,0.75) 15%, rgba(255, 255, 255, 0) 70%);
  //     background: radial-gradient(ellipse at center, rgba(0,58,121,0.75) 15%, rgba(255, 255, 255, 0) 70%);
  //   }
  // }
  &__image {
    display:flex;
    z-index:99;
    position: relative;
    align-items: center;
    width:48%;
    flex-basis:auto;
    @include mq('tablet', max) {
      display:none;
    }
    img {
      // position: absolute;
      width:90%;
      @include mq('desktop', max) {
        width:95%;
      }
    }
  }
  &__image-mobile {
    display:none;
    align-items: center;
    justify-content: center;
    @include mq('tablet', max) {
      display:flex;
      width:48%;
      flex-basis:auto;
      position: relative;
    }
  }
  &__content {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    position: relative;
    z-index:99;
    padding:50px 0;
    width:48%;
    flex-basis:auto;
    @include mq('desktop', max) {
      flex-wrap:nowrap;
    }
    @include mq('tablet', max) {
      justify-content: space-between;
      flex-wrap: wrap;
      width: 100%;
      flex-basis:auto;
      flex-direction: row;
      padding:0;
    }
    h2 {
      color:$c-blue;
      @include font-size(44);
      @include line-height(54);
      @include letter-spacing(-0.26);
      margin-top:0;
      margin-bottom:10px;
      @include mq('tablet', max) {
        @include font-size(28);
        @include line-height(38);
        @include letter-spacing(-0.16);
      }
      @include mq('phone-wide', max) {
        @include font-size(24);
        @include line-height(34);
        @include letter-spacing(-0.14);
      }
    }
    h3 {
      color:$c-blue;
      font-weight: $weight-bold;
      margin:0;
      @include font-size(19);
      @include letter-spacing(-0.11);
      @include line-height(25);
      @include mq('tablet', max) {
        display: none;
      }
    }
  }
  &__button {
    position: relative;
    display:flex;
    margin-top:30px;
    flex-direction: column;
    @include mq('tablet') {
      &.hide-desktop {
        display:none;
      }
    }
    @include mq('tablet', max) {
      margin-top:15px;
      &.hide-mobile {
        display:none;
      }
    }
  }
  &__content-top {
    display:flex;
    flex-direction: column;
    padding-bottom:50px;
    align-items: flex-start;
    justify-content: center;
    @include mq('tablet', max) {
      width:48%;
      flex-basis:auto;
      padding-top:50px;
    }
    @include mq('phablet', max) {
      position: relative;
    }
  }
  &__content-bottom {
    display: flex;
    flex-direction: row;
    border-top:1px solid $c-white;
    padding-top:30px;
    // justify-content: space-between;
    position: relative;
    z-index: 99;
    @include mq('desktop', max) {
      flex-direction: column;
    }
    @include mq('tablet', max) {
      width:100%;
      flex-basis:auto;
      flex-direction: column;
      padding-bottom:50px;
    }
  }
  &__app {
    display: flex;
    flex-direction: row;
    flex-wrap:wrap;
    margin-right:50px;
    @include mq('desktop', max) {
      &:first-child {
        margin-bottom:20px;
      }
    }
    @include mq('desktop', max) {
      margin-right:0px;
    }
  }
  &__app-image {
    display: flex;
    margin-right:20px;
    a {
      width: 85px;
      height: 85px;
      img {
        border-radius:10px;
      }
    }
  }
  &__app-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  &__app-title {
    color:$c-blue;
    font-weight: $weight-bold;
    @include font-size(19);
    @include line-height(25);
    @include letter-spacing(-0.11);
    @include mq('phone-wide', max) {
      @include font-size(17);
    }
  }
  &__app-store-images {
    margin-top:15px;
    filter: grayscale(1);
    a {
      img {
        width:90px;
        @include mq('phone-wide', max) {
          width:70px;
        }
      }
      &:first-child {
        margin-right:7.5px;
      }
    }
  }
}
