.o-video-lightbox-widget{
  &__video{
    display:flex;
    align-items:center;
    justify-content:center;
    height:700px;
    @include mq("tablet", max){
      height:400px;
    }
    img{
      width:100%;
      height: -webkit-fill-available;
      object-fit:cover;
    }
    .play-button{
      position:absolute;
      opacity:.9;
      transition:all $speed-normal ease;
      svg{
        @include mq("tablet", max){
          width:90px;
        }
        @include mq("tablet-small", max){
          width:60px;
        }
      }
      &:hover{
        transform:scale(1.2);
        opacity:1;
      }
    }
  }
}
.mfp-content {
  width:70vw !important;
  height:70vh;
  max-width:70vw !important;
}