.owl-carousel {
  .owl-nav {
    height: 0;
    .owl-prev,.owl-next {
      @include mq('tablet', max) {
        display: none;
      }
      position: absolute;
      top:50%;
      left: 20px;
      transform:translateY(-50%);
      width: 20px;
      height: 50px;
      &:focus {
        box-shadow:none;
      }
      &:hover {
        background:none;
      }
      &:after {
        content: '';
        display: block;
        width: 20px;
        height: 20px;
        border-bottom: 2px solid white;
        border-left: 2px solid white;
        transform: rotate(45deg);
        position: relative;
        left: 5px;
      }
    }
    .owl-next {
      transform:scaleX(-1) translateY(-50%);
      left:auto;
      right:20px;
      &:before{

      }
    }
  }
  .owl-dots {
    left: 20px;
    transform: none;
    @include mq('phone-wide') {
      left: 50%;
      transform: translateX(-50%);
    }
    .owl-dot:focus {
      box-shadow:none;
    }
  }
}
