
.owl-theme {
	// Styling Next and Prev buttons
	.owl-nav {
		margin-top: 10px;
		text-align: center;
		-webkit-tap-highlight-color: transparent;

		[class*='owl-'] {
			color: $owl-nav-color;
			font-size: $owl-nav-font-size;
			margin: $owl-nav-margin;
			padding: $owl-nav-padding;
			background: $owl-nav-background;
			display: inline-block;
			cursor: pointer;
			border-radius: 3px;

			&:hover {
				background: $owl-nav-background-hover;
				color:$owl-nav-color-hover;
				text-decoration: none;
			}
		}
		.disabled {
			opacity: $owl-nav-disabled-opacity;
			cursor: default;
		}
	}

	// Styling dots
	.owl-nav.disabled + .owl-dots {
		margin-top: 10px;
	}

	.owl-dots {
		text-align: center;
		-webkit-tap-highlight-color: transparent;
		position: absolute;
		bottom: 17px;
		left: 50%;
		transform: translateX(-50%);
		.owl-dot {
			display: inline-block;
			zoom: 1;
			*display: inline;

			span {
				width: $owl-dot-width;
				height: $owl-dot-height;
				margin: $owl-dot-margin;
				background: #fff;
				display: block;
				-webkit-backface-visibility: visible;
				transition: opacity 200ms ease;
				border-radius: $owl-dot-rounded;
			}

			&.active,
			&:hover {
				span{
					position: relative;
					&:after{
						content: '';
						width: $owl-dot-width*0.5;
						height: $owl-dot-height*0.5;
						position: absolute;
						top: 50%;
						left: 50%;
						transform: translate(-50%,-50%);
						background: #000;
						display: block;
						-webkit-backface-visibility: visible;
						transition: opacity 200ms ease;
						border-radius: $owl-dot-rounded;
					}
				}
			}
		}
	}
}
