.o-filters {
  display:flex;
  flex-basis:auto;
  flex-direction: column;
  align-items: flex-start;
  margin-top:15px;
  margin-bottom:15px;
  @include mq('tablet-wide', max) {
    //width:25%;
    flex-basis:auto;
  }
  @include mq('tablet', max) {
    width:100%;
    flex-basis:auto;
    &.active {
      margin-bottom:10px;
    }
  }
  &.active {
    .o-filters__mobile {
      background:$c-light-blue;
      color:$c-white;
      svg {
        path {
          fill:$c-white;
        }
      }
    }
  }
  h4 {
    @include font-size(15);
    @include letter-spacing(-0.09);
    padding-bottom:15px;
    margin-top:0;
    margin-bottom:20px;
    border-bottom:1px solid $c-lighter-gray;
  }
  label {
    color:$c-blue;
    @include font-size(15);
    @include line-height(28);
    @include letter-spacing(-0.09);
    min-width:max-content;
    svg, img {
      position: relative;
      top:3px;
      margin-left:4px;
      margin-right:6px;
      width:20px;
    }
  }
  input[type="checkbox"] {
    @include appearance;
    background: $c-white;
    position: relative;
    top:4px;
    border:1px solid $c-lighter-gray;
    width:15px;
    height:15px;
    border-radius:1px;
    &:checked {
      box-shadow:none;
      &::after {
        content: '';
        background-color: $c-blue;
        width: 100%;
        height: 100%;
        display: block;
        position: relative;
      }
    }
  }
  .no-filters-available {
    @include font-size(14);
    @include line-height(20);
  }
  &__mobile {
    display:flex;
    flex-wrap:wrap;
    color:$c-light-blue;
    cursor:pointer;
    border:1px solid $c-light-blue;
    border-radius:99px;
    padding:7.5px 20px;
    font-weight:700;
    align-items: center;
    @include mq('tablet') {
      display:none;
    }
    svg, img {
      width:20px;
      height:18px;
      margin-right:10px;
    }
    // &:before {
    //   font-family:'Font Awesome 5 Free';
    //   content: '\f0b0';
    //   font-style: normal;
    //   font-weight:$weight-heavy;
    //   @include font-size(13);
    //   display:flex;
    //   position: relative;
    //   margin-right:10px;
    //   transition: transform $speed-slow ease-in-out;
    // }
  }
  &__widgets {
    display: flex;
    flex-direction: column;
    width:100%;
    margin-top:50px;
    @include mq('tablet', max) {
      display:none;
      margin-top:30px;
    }
  }
  &__widget {
    display: flex;
    flex-direction: column;
    margin-bottom:30px;
    &.hide-widget {
      display:none;
    }
  }
}
