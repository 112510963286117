.a-featured-image {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: background-color $speed-fast ease-out;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &.-top {
    &.-left {
      img {
        object-position: 0 0;
      }
    }
    &.-center {
      img {
        object-position: 50% 0;
      }
    }
    &.-right {
      img {
        object-position: 100% 0;
      }
    }
  }

  &.-middle {
    &.-left {
      img {
        object-position: 0 50%;
      }
    }
    &.-center {
      img {
        object-position: 50% 50%;
      }
    }
    &.-right {
      img {
        object-position: 100% 50%;
      }
    }
  }

  &.-bottom {
    &.-left {
      img {
        object-position: 0 100%;
      }
    }
    &.-center {
      img {
        object-position: 50% 100%;
      }
    }
    &.-right {
      img {
        object-position: 100% 100%;
      }
    }
  }

  //Fix for Internet Explorer
  background: transparent no-repeat center center/cover;
  &.-top {
    background-position-y: top;
  }
  &.-bottom {
    background-position-y: bottom;
  }
  &.-left {
    background-position-x: left;
  }
  &.-right {
    background-position-x: right;
  }
}
