.o-testimonials-widget {
  *{
    color:$c-blue;
  }
  .title{
    @include font-size(44);
    @include line-height(50);
    @include letter-spacing(-0.26);
    @include mq('tablet-wide', max) {
      @include font-size(28);
      @include line-height(34);
      @include letter-spacing(-0.16);
    }
  }
  &__wrapper{
    display:flex;
    gap:40px;
    &.no-carousel{
      .o-testimonials-widget__testimonial{
        width:50%;
      }
    }
  }
  &__testimonial{
    display:flex;
    gap:30px;
    @include mq('tablet-small', max) {
      flex-wrap:wrap;
      gap:0;
    }
    .testimonial{
      @include font-size(24);
      font-weight:bold;
      line-height:1.4;
      @include mq('tablet-wide', max) {
        @include font-size(18);
      }
    }
    .author{
      @include font-size(16);
      @include mq('tablet-wide', max) {
        @include font-size(14);
      }
    }
    img{
      width:95px !important;
      height:95px;
      border-radius:50px;
      object-fit:cover;
    }
  }
  .owl-nav{
    position:relative;
    display:flex;
    gap:20px;
    height:40px;
    justify-content: flex-end;
    margin-top:20px;
    .owl-prev,
    .owl-next{
      transform:none;
      position:relative;
      top:0;
      left:0;
      height:40px;
      width:40px;
      @include mq("tablet", max){
        display:block;
      }
      &:after{
        display: flex;
        align-items: center;
        justify-content: center;
        height: 40px;
        width:40px;
        content: url(//dev.nutrinorm.nl/wp-content/themes/nutrinorm/css/../svg/interface/icon-arrow-small-right.svg);
        position: absolute;
        top: 0px;
        padding-top: 4px;
        background-color:$c-orange;
        transform:none;
        border:none;
      }
      &.disabled{
        opacity:.5;
      }
    }
    .owl-prev{
      &:after{
        transform:rotate(180deg);
      }
    }
  }
}
