.svg--branding--logo-nutrinorm-dims {
	width: 283px;
	height: 100px;
}

.svg--branding--logo-nutrinorm-no-slogan-dims {
	width: 1043.95px;
	height: 354.44px;
}

.svg--branding--logo-oci-dims {
	width: 339.5px;
	height: 145px;
}

.svg--branding--nutrinorm-logo-dims {
	width: 790.23px;
	height: 234.18px;
}

.svg--interface--down-arrow-dims {
	width: 49px;
	height: 49px;
}

.svg--interface--icon-arrow-big-down-dims {
	width: 14px;
	height: 20.2px;
}

.svg--interface--icon-arrow-big-left-dims {
	width: 20px;
	height: 14px;
}

.svg--interface--icon-arrow-big-right-dims {
	width: 20px;
	height: 15px;
}

.svg--interface--icon-arrow-down-dims {
	width: 17px;
	height: 9px;
}

.svg--interface--icon-arrow-down-dark-dims {
	width: 20px;
	height: 11px;
}

.svg--interface--icon-arrow-left-dims {
	width: 20px;
	height: 14px;
}

.svg--interface--icon-arrow-right-dims {
	width: 10px;
	height: 17px;
}

.svg--interface--icon-arrow-small-down-dims {
	width: 17px;
	height: 9px;
}

.svg--interface--icon-arrow-small-right-dims {
	width: 10px;
	height: 17px;
}

.svg--interface--icon-blog-dims {
	width: 18px;
	height: 15.5px;
}

.svg--interface--icon-book-dims {
	width: 18px;
	height: 15px;
}

.svg--interface--icon-camera-dims {
	width: 512px;
	height: 512px;
}

.svg--interface--icon-camera-heart-dims {
	width: 512px;
	height: 512px;
}

.svg--interface--icon-check-dims {
	width: 24px;
	height: 17px;
}

.svg--interface--icon-clock-dims {
	width: 16px;
	height: 16px;
}

.svg--interface--icon-close-dims {
	width: 28px;
	height: 28px;
}

.svg--interface--icon-ecology-dims {
	width: 512px;
	height: 512px;
}

.svg--interface--icon-facebook-dims {
	width: 80px;
	height: 80px;
}

.svg--interface--icon-facebook-hover-dims {
	width: 80px;
	height: 80px;
}

.svg--interface--icon-filter-dims {
	width: 23px;
	height: 21px;
}

.svg--interface--icon-folder-dims {
	width: 512px;
	height: 512px;
}

.svg--interface--icon-leaf-dims {
	width: 28.75px;
	height: 28.749px;
}

.svg--interface--icon-light-bulb-dims {
	width: 20px;
	height: 20px;
}

.svg--interface--icon-linkedin-dims {
	width: 80px;
	height: 80px;
}

.svg--interface--icon-linkedin-hover-dims {
	width: 80px;
	height: 80px;
}

.svg--interface--icon-play-button-dims {
	width: 97px;
	height: 97px;
}

.svg--interface--icon-plus-dims {
	width: 28px;
	height: 28px;
}

.svg--interface--icon-search-dims {
	width: 21px;
	height: 21px;
}

.svg--interface--icon-star-dims {
	width: 16px;
	height: 16px;
}

.svg--interface--icon-sustainability-dims {
	width: 512px;
	height: 512px;
}

.svg--interface--icon-thumbs-up-dims {
	width: 18px;
	height: 17px;
}

.svg--interface--icon-twitter-dims {
	width: 80px;
	height: 80px;
}

.svg--interface--icon-twitter-hover-dims {
	width: 80px;
	height: 80px;
}

.svg--interface--icon-x-dims {
	width: 42px;
	height: 42px;
}

.svg--interface--icon-x-hover-dims {
	width: 42px;
	height: 42px;
}

.svg--interface--icon-youtube-dims {
	width: 80px;
	height: 80px;
}

.svg--interface--icon-youtube-hover-dims {
	width: 80px;
	height: 80px;
}

