.mc4wp-form-fields {
  display:flex;
  width:100%;
  // @include mq('tablet', max) {
  //   flex-direction: column;
  // }
  @include mq('tablet-halfwide', max) {
    flex-direction: column;
  }
  @include mq('tablet-small', max) {
    align-items: flex-end;
  }
}
