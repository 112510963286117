.o-photo-contest-winner-text {
  display: flex;
  width: 50%;
  margin: 0 50px 0 auto;
  @include mq('desktop', max) {
    width: 75%;
    margin: 0 0 0 auto;
  }
  @include mq('tablet', max) {
    width:100%;
    margin:auto;
  }
  p {
    margin:0;
  }
}
