.o-big-buttons-widget {
  display:flex;
  flex-direction: row;
  flex-basis:100%;
  flex-wrap:wrap;
  justify-content: center;
  &.-small {
    margin-top:-50px;
  }
  &.-medium {
    margin-top:-75px;
  }
  &.-large {
    margin-top:-100px;
  }
  a {
    display:flex;
    flex-basis:25%;
    align-items: center;
    justify-content: center;
    border-radius:0;
    padding:17.5px 20px;
    @include font-size(20);
    @include line-height(30);
    @include letter-spacing(-0.1);
    @include mq('tablet-wide', max) {
      padding: 15px 20px;
      @include font-size(18);
      @include line-height(26);
      @include letter-spacing(-0.1);
    }
    @include mq('tablet-wide', max) {
      flex-basis:75%;
    }
  }
}
