.o-button-widget {
  position: relative;
  display: flex;
  &__button {
    position: relative;
    display: flex;
  }
}
.so-panel:has(.o-button-widget.inline){
  width:fit-content;
  display:inline-block;
  margin-right:100px !important;
}
