.m-fertilization-buttons {
  display:flex;
  justify-content: space-between;
  flex-direction: column;
  width:100%;
  @include mq('tablet', max) {
    width: calc(100vw - 45px);
    overflow: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;
    &::-webkit-scrollbar {
      display:none;
    }
  }
  &__wrapper {
    display: flex;
    justify-content: space-between;
  }
  &__button {
    width:23%;
    height:47px;
    align-items: center;
    justify-content: center;
    border: 1px solid rgba(255, 255, 255, 0.45);
    margin-bottom:20px;
    @include mq('desktop', max) {
      @include font-size(22);
    }
    @include mq('desktop-small', max) {
      @include font-size(21);
    }
    @include mq('tablet-wide', max) {
      @include font-size(20);
    }
    @include mq('tablet', max) {
      @include font-size(25);
      min-width:200px;
      margin-right:15px;
      &:last-child {
        margin-right:0;
      }
    }
  }
  &__text {
    @include mq('tablet') {
      display:none;
    }
    margin-top:25px;
    color:$c-light-blue;
    @include font-size(13);
    display:flex;
    align-items: center;
    svg {
      width:20px;
      margin-left:10px;
    }
    // &:after {
    //   font-family:'Font Awesome 5 Free';
    //   content: '\f061';
    //   font-style: normal;
    //   font-weight:$weight-heavy;
    //   @include font-size(13);
    //   display:flex;
    //   position: relative;
    //   left:7.5px;
    // }
  }
}
