.panel-layout {
  overflow:hidden;
  width:100%;
  padding-bottom:40px;
  .panel-grid {
    &.-gray {
      background-color:$c-ultimate-lightest-gray;
    }
  }
}
.o-content {
  .panel-layout {
    padding-bottom:0;
  }
}
