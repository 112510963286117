.o-list-widget {
  &.-blue {
    color:$c-blue;
  }
  &.-black {
    color:$c-black;
  }
  &.-gray {
    color:$c-gray;
  }
  &.-white {
    color:$c-white;
  }
  &.-icon-color-blue {
    ul {
      li {
        &:before {
          color:$c-blue;
        }
      }
    }
  }
  &.-icon-color-light-green {
    ul {
      li {
        &:before {
          color:$c-light-green;
        }
      }
    }
  }
  &.-icon-color-orange {
    ul {
      li {
        &:before {
          color:$c-orange;
        }
      }
    }
  }
  &.-check-icon {
    ul {
      list-style:none;
      padding-left:5px;
      li {
        padding-bottom:5px;
        &:before {
          font-family:'Font Awesome 5 Free';
          content: '\f00c';
          font-weight:$weight-heavy;
          padding-right:10px;
        }
      }
    }
  }
}
