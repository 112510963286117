.o-nav {
  // display:flex;
  // justify-content: flex-start;
  position: fixed;
  z-index: 3;
  overflow-y:auto;
  height:0;
  top:110px;
  width: 100%;
  flex-basis:auto;
  left:0;
  margin-right:10px;
  margin-bottom:7px;
  display:flex;
  flex-direction:row;
  align-items:center;
  justify-content: center;
  height:48px;
  .sticky-nav & {
    z-index: 10;
    padding: 0;
    left:0;
    width: 100%;
    .o-nav__payoff {
      opacity: 0;
    }
    .o-nav__logo {
      opacity: 1;
    }
  }
  @include mq('desktop') {
    height: auto !important;
    min-height:48px;
    overflow: visible;
    top:auto;
    left:auto;
    //align-items: flex-end;
    justify-content: flex-end;
    position: relative;
  }
  @include mq('desktop', max) {
    height:0;
  }
  &__menu {
    @include mq('desktop', max) {
      display:none;
    }
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-basis:100%;
    .search-form{
      display:none;
      .o-content__search-button{
        background-color:transparent;
        &:hover{
          background-color:transparent;
          box-shadow:none;
        }
        path{
          fill:$c-blue;
        }
      }
      .o-content__search-form input[type=search]{
        border-bottom: solid 1px $c-blue;
        padding-left:0;
      }
    }
    .search{
      margin-left:50px;
      cursor:pointer;
      p{
        margin: 0;
        font-size: 13px;
      }
      path{
        fill:$c-blue;
      }
      .activate,
      .deactivate{
        gap: 10px;
        align-items: center;
      }
      .deactivate{
        display:none;
      }
      .activate{
        display:flex;
      }
      &.active{
        .deactivate{
          display:flex;
        }
        .activate{
          display:none;
        }
      }
    }
    ul {
      display:flex;
      flex-wrap: wrap;
      list-style:none;
      width:100%;
      flex-basis:auto;
      @include reset-ul;
      @include mq('desktop') {
        //padding:0 25px;
        padding:0 0 0 25px;
      }
      li {
        display:flex;
        width:100%;
        flex-basis:auto;
        vertical-align: middle;
        display: flex;
        position: relative;
        white-space: nowrap;
        &.fertilization {
          width:175px;
        }
        &.fertilizers {
          width:185px;
        }
        &.soil {
          width:145px;
        }
        &.sprinkle {
          width:155px;
        }
        &.products {
          width:175px;
        }
        padding:0px;
        @include mq('desktop') {
          flex-basis:auto;
          display: inline-flex;
          background: none;
        }
        &:last-child {
          a {
            padding-right:0;
          }
        }
        &.current-menu-item,
        &.current-category-ancestor {
          a {
            color:$c-light-blue;
          }
          &.fertilization{
            a{
              color:$c-green;
            }
          }
          &.fertilizers{
            a{
              color:$c-pink;
            }
          }
          &.soil{
            a{
              color:$c-orange;
            }
          }
          &.sprinkle{
            a{
              color:$c-orange;
            }
          }
        }
        a {
          display: flex;
          padding: 8px 10px 11px;
          text-decoration: none;
          font-weight: $weight-medium;
          @include font-size(19);
          @include letter-spacing(0.68);
          transition: all $speed-fast ease-out;
          color:$c-blue;
          font-weight:$weight-bold;
          &:hover {
            color:$c-light-blue;
          }
        }
      }
    }
  }
  &__mobile-menu-wrapper {
    display: block;
    @include mq('desktop', max) {
      width:100%;
    }
    .search-form{
      background-color:$c-off-white;
      padding: 25px 0 50px 0;
      width: 100%;
      input{
        width: calc(100% - 45px);
      }
    }
  }
  &__mobile-menu {
    @include mq('desktop') {
      display:none;
    }
    background-color:$c-off-white;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction:column;
    width:100%;
    flex-basis:auto;
    padding: 25px;
    .menu-item{
      color:$c-blue;
      text-decoration:none;
      padding-right: 40px;
      font-weight:bold;
      &:not(:first-of-type){
        margin-right:20px;
      }
    }
    nav{
      width:100%;
    }
    > ul {
      height:100%;
      overflow:auto;
      -ms-overflow-style: none;
      scrollbar-width: none;
      &::-webkit-scrollbar {
        display:none;
      }
    }
    .sub-menu-wrapper {
      display:none;
      width:100%;
    }
    .sub-menu {
      flex-wrap: wrap;
      flex-direction: row;
      padding-left:20px;
      padding-top:10px;
      margin:10px 0;
      border-top:1px solid $c-lighter-gray;
      li {
        flex-direction: column;
        flex: 0 0 45%;
        @include mq('phablet', max) {
          width:100%;
          flex-basis:auto;
        }
        &.current-menu-item {
          > a {
            color:$c-light-blue;
            text-decoration: underline;
          }
        }
        a {
          @include font-size(18);
          @include line-height(34);
          @include letter-spacing(-0.1);
          color:$c-blue;
          font-weight:$weight-regular;
          padding: 0px;
          img {
            height:20px;
            margin-bottom:15px;
          }
          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
    ul {
      display:flex;
      list-style:none;
      flex-direction: column;
      width:100%;
      @include reset-ul;
      @include mq('desktop') {
        padding:0 0 0 25px;
      }
      li {
        display:flex;
        vertical-align: middle;
        position: relative;
        white-space: nowrap;
        flex-direction: row;
        flex-wrap:wrap;
        @include mq('desktop') {
          flex-basis:auto;
          display: inline-flex;
          background: none;
        }
        &.sub-menu-active {
          > a {
            width:100%;
            flex-basis:auto;
          }
          // &.products {
          //   > a {
          //     margin-bottom:20px;
          //   }
          // }
          &.fertilization {
            a {
              text-decoration: none;
            }
            // > a {
            //   margin-bottom:20px;
            // }
          }
        }
        &.menu-item-has-children {
          > a {
            &::after {
              // font-family:'Font Awesome 5 Free';
              content: url('../svg/interface/icon-arrow-small-down.svg');
              // font-style: normal;
              // font-weight:$weight-heavy;
              // @include font-size(21);
              display:inline-flex;
              color: $c-blue;
              position: absolute;
              right:0;
              cursor:pointer;
              transition: all $speed-fast ease-out;
            }
          }
          &.sub-menu-active {
            a {
              &::after {
                transform:rotate(-180deg);
              }
            }
          }
        }
        &.current-menu-item {
          > a {
            color:$c-light-blue;
          }
        }
        // &.products {
        //   .sub-menu {
        //     flex-wrap: nowrap;
        //     flex-direction: column;
        //     li {
        //       padding-bottom:20px;
        //       &:last-child {
        //         padding-bottom:0px;
        //       }
        //     }
        //   }
        // }
        &.fertilization {
          .sub-menu {
            padding-left:0;
            padding-top:20px;
            justify-content: space-between;
            a {
              color:$c-white;
              padding:5px 20px;
              font-weight:$weight-bold;
              justify-content: center;
              margin:0;
              @include font-size(25);
              @include letter-spacing(-0.15);
              &:hover {
                text-decoration: none;
              }
            }
            li {
              @include mq('phone') {
                flex: 0 0 47.5%;
              }
              margin-bottom:15px;
              &.green {
                a {
                  background-color:$c-green;
                }
              }
              &.orange {
                a {
                  background-color:$c-orange;
                }
              }
              &.pink {
                a {
                  background-color:$c-pink;
                }
              }
              &.blue {
                a {
                  background-color:$c-blue;
                }
              }
            }
          }
        }
        &.products {
          .sub-menu {
            padding-top:20px;
          }
        }
        a {
          display: inline-flex;
          padding: 3px 0px;
          text-decoration: none;
          font-weight: $weight-medium;
          @include font-size(24);
          @include line-height(46);
          @include letter-spacing(0.86);
          transition: all $speed-fast ease-out;
          color:$c-blue;
          font-weight:$weight-bold;
          &:hover {
            color:$c-light-blue;
          }
        }
      }
    }
  }
  &__mobile-menu-banner {
    @include mq('desktop') {
      display:none !important;
    }
    @include mq('phone-small', max) {
      display:none !important;
    }
    display: flex;
    width:100%;
    position: relative;
    height:143px;
    justify-content: space-between;
    background-image: radial-gradient(circle 98px at 22.36% 574.24%, #87bc26 0%, #e7e8e7 100%, #e8e8e8 100%, #e8e8e8 100%);
    span {
      display:flex;
      color:$c-blue;
      font-weight:$weight-bold;
      @include font-size(18);
      @include letter-spacing(-0.1);
      margin-bottom:15px;
    }
  }
  &__mobile-menu-banner-image {
    display:flex;
    flex-direction: column;
    width:150px;
    flex-basis:auto;
    z-index:99;
    position: relative;
    left:30px;
    img {
      position: absolute;
      bottom:0;
    }
  }
  // &__mobile-menu-banner-radial-gradient-background-wrapper {
  //   position: absolute;
  //   width: 200px;
  //   height: 120%;
  //   left:-45px;
  //   top:0px;
  //   overflow: hidden;
  //   @include mq('phone-small', max) {
  //     width:175px;
  //   }
  // }
  // &__mobile-menu-banner-radial-gradient-background {
  //   position: absolute;
  //   width: 100%;
  //   height: 120%;
  //   top:-20%;
  //   background: -moz-radial-gradient(center, ellipse farthest-corner, rgba(135,188,38,0.75) 15%, transparent 70%);
  //   background: -webkit-gradient(radial, center center, 0px, center center, 100%, color-stop(15%, rgba(135,188,38,0.75)), color-stop(70%, transparent));
  //   background: -webkit-radial-gradient(center, ellipse farthest-corner, rgba(135,188,38,0.75) 15%, transparent 70%);
  //   background: -o-radial-gradient(center, ellipse farthest-corner, rgba(135,188,38,0.75) 15%, transparent 70%);
  //   background: -ms-radial-gradient(center, ellipse farthest-corner, rgba(135,188,38,0.75) 15%, transparent 70%);
  //   background: radial-gradient(ellipse at center, rgba(135,188,38,0.75) 15%, transparent 70%);
  // }
  &__mobile-menu-banner-content {
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    padding-right:30px;
    z-index:999;
    text-align: right;
  }
  &__mobile-menu-banner-apps {
    display:flex;
    a {
      width:47px;
      height: 47px;
      display:flex;
      flex-direction: column;
      &:first-child {
        margin-right:20px;
      }
      img {
        width:100%;
        height:100%;
        border-radius:5px;
      }
    }
  }
}
