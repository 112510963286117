.o-check-list-widget {
  padding:2em 0;
  &__wrapper{
    gap:40px;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
  }
  &__check{
    width:calc(33% - 40px);
    min-width:300px;
    display:flex;
    align-items:flex-start;
    p{
      color:$c-blue;
      @include font-size(17);
      margin:-5px 0 0 25px;
    }
    &.-heading-color-blue {
      path {
        fill:$c-blue;
      }
    }
    &.-heading-color-light-blue {
      path {
        fill:$c-light-blue;
      }
    }
    &.-heading-color-orange {
      path {
        fill:$c-orange;
      }
    }
    &.-heading-color-light-green {
      path{
        fill:$c-light-green;
      }
    }
  }
}
