.a-divider {
  display: flex;
  width:80%;
  bottom:200px;
  align-items: center;
  margin:50px 0;
  border-bottom:1px solid $c-blue;
  @include mq('tablet-wide', max) {
    width:100%;
  }
  h3.a-divider__title {
    margin-right:30px;
    display:flex;
    width:auto;
    color:$c-blue;
    font-weight:$weight-bold;
    @include font-size(40);
    @include mq('tablet-wide', max) {
      @include font-size(30);
    }
  }
  &.-alternative {
    .a-divider__title {
      &:nth-of-type(1) {
        @include mq('tablet', max) {
          display:none;
        }
      }
      &:nth-of-type(2) {
        @include mq('tablet') {
          display:none;
        }
      }
    }
  }
}
