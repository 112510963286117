.o-products-widget {
  &:not(.list){
    .container{
      background-color:transparent!important;
    }
    .o-products-widget__wrapper{
      background-color:$c-ultimate-uber-lightest-gray;
      padding:5em 0;
      > .container{
        display:grid;
        grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
        justify-content: space-evenly;
        gap:30px;
        @include mq("tablet-halfiwde", max){
          grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
        }
        @include mq("tablet", max){
          grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
        }
      }
    }
  }
  .title{
    color:$c-blue;
    @include font-size(44);
    @include line-height(50);
    @include letter-spacing(-0.26);
    @include mq('tablet-wide', max) {
      @include font-size(28);
      @include line-height(34);
      @include letter-spacing(-0.16);
    }
  }
  &__product{
    display:flex;
    align-items:center;
    gap:30px;
    width:100%;
    .thumbnail{
      width:30%;
      img{
        width:100%;
        border-radius: 50%;
      }
    }
    .right{
      width:calc(70% - 30px);
      .logo{
        width:100%;
        max-width:250px;
        margin-bottom:20px;
      }
    }
  }
}
.o-content{
  .o-products-widget__wrapper{
    margin: 5em 0;
    width:75%;
    @include mq("tablet-halfwide", max){
      width:100%;
    }
    .thumbnail{
      width:20%;
      @include mq("tablet-halfwide", max){
        width:30%;
      }
      @include mq("tablet-small", max){
        width:50%;
      }
    }
    .right{
      @include mq("tablet-small", max){
        width:100%;
      }
    }
  }
  .o-products-widget__product{
    align-items:flex-start;
    margin-bottom:100px;
    @include mq("tablet-small", max){
      flex-wrap:wrap;
    }
  }
}